// eslint-disable-line
import React, { useEffect, useState } from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import  { Balance }  from './balance';
import MorList  from './MorList';
import Mor  from './Mor';
import Atc  from './Atc';
import { MorNames, AtsNames } from '../../../instruments/utils';
import { setTraslate } from '../../../instruments/setTraslate';
import { useSelector } from 'react-redux';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

function Company(props) {
    const state = useSelector(selectState);
    const translate = state.translate;
    const [ list, setList ] = useState({ ...listing.list });

    useEffect(()=>{
        const _list = setTraslate({ ...listing.list }, translate);
        setList(_list);
    }, [ translate, listing.list ]);

    const CustomComponents = {
        balance:       Balance,
        balance_phone: Balance,
        mor_ids:       MorList,
        mor_type:      Mor,
        atc_type:      Atc,
    };
    const listSelect = {
        mor_type: Object.keys(MorNames).map((i)=>{
            return { value: i, label: MorNames[ i ] };
        }),
        atc_type: Object.keys(AtsNames).map((i)=>{
            return { value: i, label: AtsNames[ i ] };
        }),
    };

    const btns = [
        {
            component: 'Import',
            apiUrl:    `/import_mor`,
            name:      'Import mor',
            type:      'phone',
        },
    ];

    return (
        <ListConstructor
            { ...props }
            isShowAddBtn
            // isShowDuplicate
            sortOrderDefault
            CustomComponents = { CustomComponents }
            btns = { btns }
            fields = { list }
            limit = { 10 }
            listSelect = { listSelect }
            moduleTitle = { translate.mn2 }
            sortDefault = 'name'
            type = { 'company' }
            urlApi = { '/company' }
        />
    );
}

export default Company;
