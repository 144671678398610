/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { api } from '../../../Api';
import ListItem from './item';
import EditItem from './edit';
import ListFilter from './filter';
import { Add } from '@mui/icons-material';
import { Button, Drawer, Box  } from '@mui/material';
import { IconS, thTextStyle } from './style';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import Styles from '../../../theme/styles/table.module.scss';
import StylesFilter from '../../../theme/styles/filter.module.scss';
import StylesForm from '../../../theme/styles/form.module.scss';
import StylesHeader from '../../../theme/styles/pageHeader.module.scss';
import Pagination from '../../Pagination';
import Import from '../Import';
import ImportCsv from '../Import/importCsv';
import { FilterSearch } from '../FormConstructor/FilterSearch';
import Tabs from './tabs';
import { titlesAdd } from '../../../instruments/utils';

const selectState = (state) => ({
    socket:    state.swapi.socket,
    userData:  state.swapi.userData,
    company:   state.swapi.company,
    translate: state.swapi.translate,

});

const btnComponents = {
    Import:    Import,
    ImportCsv: ImportCsv,
};

function ListConstructor(props) {
    const state = useSelector(selectState);
    const  userData  = state.userData;
    const  company  = state.company;
    const translate = state.translate;

    const {
        dataBack = {},
        fields,
        limit = 15,
        listSelect = {},
        sortDefault = 'createdAt',
        sortOrderDefault = false,
        urlApi,
        type,
        search,
        setCallBackData,
        setCallBack,
        isShowAddBtn, isDefaultFilter,
        filterOutside = {}, isShowDuplicate,
        selectedAllTxt, selectedAllStaus,
        moduleTitle = '',
        btns = [],
        tabs = [],
    } = props;

    const [ page, setCurrentPage ] = useState(1);
    const [ list, setList ] = useState([]);
    const [ listSelects, setListSelects ] = useState(listSelect);
    const [ totalPagges, setTotal ] = useState(0);
    const [ edit, setEdit ] = useState(null);
    const { user } = useParams();
    const [ loading, setLoading ] = useState(false);
    const [ clear, setClear ] = useState(false);
    const [ sortOrder, setSortOrder ] = useState(sortOrderDefault);
    const [ filter, setFilter ] = useState(filterOutside);
    const [ sort, setSort ] = useState(sortDefault ? sortDefault : 'createdAt');
    const [ isAdd, setIsAdd ] = useState(false);
    const [ defaultItem, setDefaultItem ] = useState(false);
    const [ componentName, setComponentName ] = useState(false);
    const [ handleSort, setHandleSort ] = useState('');
    const [ status, setStatus ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ _isDefaultFilter, setDefaultFilter ] = useState(isDefaultFilter);
    const [ selectedDuplicates, setSelectedDuplicates ] = useState([]);
    const [ openDrawer, setOpenDrawer ] = useState(false);
    const handleCheckboxChange = (itemId) => {
        if (selectedDuplicates.includes(itemId)) {
            setSelectedDuplicates(selectedDuplicates.filter((id) => id !== itemId));
        } else {
            setSelectedDuplicates([ ...selectedDuplicates, itemId ]);
        }
    };
    const handleMergeDuplicates = async () => {
        const param = {
            selectedDuplicates: selectedDuplicates,
            type:               type,
            company_id:         company._id,
        };
        const result = await api.postData(param, urlApi + '_clearDuplicate');
        if (result) {
            setSelectedDuplicates([]);
            if (result.removeIds && result.removeIds.length > 0) {
                const _list = list.filter((el) => result.removeIds.indexOf(el._id) === -1);
                setList(_list);
            }
        }
    };
    useEffect(() => {
        setCurrentPage(1);
        const defaultItem = {
            _id: 'new',
        };
        const defaultFilter = { ...filterOutside };
        for (const el of Object.keys(fields)) {
            if (fields[ el ].type !== 'edit' && fields[ el ].type !== 'btn' && fields[ el ].type !== 'delete') {
                defaultItem[ el ] = el !== '_id' ? null : defaultItem._id;
                if (fields[ el ].defaultFilter) {
                    defaultFilter[ el ] = fields[ el ].defaultFilter;
                }
                if (fields[ el ].type === 'arrSelect') {
                    defaultItem[ el ] = [];
                }
            }
        }
        setFilter(defaultFilter);
        setDefaultItem(defaultItem);
        let _componentName = 'admin/' + type;
        setComponentName(_componentName);
    }, [ fields ]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose =  async (confirmed) => {
        setOpen(false);
        if (confirmed) {
            setStatus((prevStatus) => !prevStatus);
            const newList = list.map((item) => {
                return {
                    ...item,
                    status: !status,
                };
            });
            setList(newList);

            await selectedAllStaus(!status, filter);
        }
    };


    const sortData = (value) => {
        if (sort === value) {
            setSortOrder(!sortOrder);
        } else {
            setSort(value);
        }
        setHandleSort(value);
    };

    const changePage = (page) => {
        setCurrentPage(page);
    };

    const setNewItem = (item) => {
        setList([ item, ...list ]);

        return true;
    };

    // boroda 21.12.2024
    // передаю фільтр при виклику, бо при очистці фільтрів не втигав оновлювались фільтр в стейті
    const getData = async (filter) => {
        setLoading(true);
        const getData = {
            page:       page,
            sort:       sort,
            limit:      limit,
            sortOrder:  !!sortOrder,
            filterData: filter,
            search:     search,
            handleSort: handleSort,
            user:       user,
            type:       type,
            company_id: company._id,

            ...dataBack,
        };
        if (userData.role === 'client' && !getData.filterData.companyId) {
            getData.filterData.companyId = company._id;
        }
        console.log(getData.filterData);
        const data = await api.postData(getData, urlApi);
        if (typeof setCallBackData === 'function' && data) {
            setCallBackData(data);
        }
        if (data && data.sort) {
            setSort(data.sort.text);
            setSortOrder(data.sort.order);
        }

        if (data && data.list) {
            setList(data.list);
            if (data?.list?.[ 0 ]?.status) {
                setStatus(data.list[ 0 ].status);
            }
        } else {
            setList([]);
            setTotal(0);
        }
        if (data.total) {
            setTotal(Math.ceil(data.total / limit));
        } else {
            setTotal(0);
        }
        if (data && data.selectedList) {
            setListSelects({ ...listSelects, ...data.selectedList });
        }
        setLoading(false);
    };
    useEffect(() => {
        setClear(false);
    }, [ clear ]);

    useEffect(() => {
        if (typeof setCallBack === 'function') {
            setCallBack(filter);
            const isEqual = JSON.stringify(_isDefaultFilter) === JSON.stringify(filter);

            if (!isEqual) {
                setFilter(filter); // Оновлюємо стейт, якщо є відмінності
            }
        }
        if (_isDefaultFilter) {
            if (Object.keys(filter).length > 0) {
                getData(filter);
            }
        } else {
            getData(filter);
        }
    }, [
        _isDefaultFilter, sort, sortOrder,
        search, type, company._id,
    ]);
    useEffect(() => {
        getData(filter);
    }, [ page ]);

    const clearFilter = ()=>{
        const filter = company?._id ? { companyId: company._id } : {};
        setCurrentPage(1);
        setFilter(filter);
        setDefaultFilter(false);
        setClear(!clear);
        setSort(sortDefault ? sortDefault : 'createdAt');
        getData(filter);
        if (setCallBack && typeof setCallBack === 'function') {
            setCallBack(filter);
        }
    };

    useEffect(() => {
        const filter = company?._id ? { ...filterOutside, companyId: company._id } : { ...filterOutside };
        setFilter(filter);
        setDefaultFilter(false);
        setClear(!clear);
        setSort(sortDefault ? sortDefault : 'createdAt');
        getData(filter);
    }, [ window.location.pathname ]);

    // boroda 21.12.2024
    // спацювання фільтрів по кнопці, а не на льоту
    const setFilterData = ()=>{
        getData(filter);
        setOpenDrawer(false);
        if (setCallBack && typeof setCallBack === 'function') {
            setCallBack(filter);
        }
    };

    // boroda 21.12.2024
    // закривання модалки фільтрів без застосування пошуку
    const resetFilterData = ()=>{
        setOpenDrawer(false);
    };

    const updateList = ()=>{
        getData(filter);
    };

    const filterJSX = fields
        ? Object.keys(fields)
            .filter((key) => {
                const field = fields[ key ];
                let show = true;
                field.sort_filter = field.sort_filter ? field.sort_filter : 1;
                if (field.onlyNew && !isAdd) {
                    return false;
                }
                if (fields[ key ].roles && show) {
                    show = fields[ key ].roles.includes(userData.role);
                }

                return field.sort_filter && field.sort_filter > 0 && show;
            })
            .sort((a, b) => {
                return fields[ a ].sort_filter < fields[ b ].sort_filter ? -1 : 1;
            })
            .map((key) => {
                const field = fields[ key ];
                let CustomComponentFilter = null;
                if (field.customFilter && props.CustomComponentsFilter && props.CustomComponentsFilter[ key ]) {
                    CustomComponentFilter = props.CustomComponentsFilter[ key ];
                }

                const resultJSx = field.filter && (
                    <Box sx = {{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '16px' }}>
                        <ListFilter
                            { ...props }
                            clear = { clear }
                            filter = { filter }
                            label = { field.title }
                            labelTxt = { field.title || '' }
                            list = { listSelects[ key ] ? listSelects[ key ] : [] }
                            listSelects = { listSelects }
                            name = { key }
                            setCurrentPage = { setCurrentPage }
                            setDefaultFilter = { setDefaultFilter }
                            setFilter = { setFilter }
                            type = { field.typeSearch ? field.typeSearch : key }
                        />
                        {CustomComponentFilter && (
                            <CustomComponentFilter
                                field = { key }
                                game = { props.game }
                            />
                        )}
                    </Box>
                );

                return resultJSx ? (
                    <React.Fragment key = { key }>
                        {resultJSx}
                    </React.Fragment>
                ) : null;
            })
        : null;


    const headerJSX = fields
        ? Object.keys(fields).map((key) => {
            const field = fields[ key ];
            let show = true;

            if (field.onlyNew && !isAdd) {
                return null;
            }
            if (fields[ key ].roles && show) {
                show = fields[ key ].roles.includes(userData.role);
            }

            let resultJSx = '';
            if (show) {
                resultJSx = (
                    <>
                        <span style = { thTextStyle }>{field.title ? field.title : ''}</span>
                        {field.sort && field.type !== 'checkbox' ? (
                            <div
                                className = { Styles.sort }
                                onClick = { () => field.sort &&  field.type !== 'checkbox'  ? sortData(key) : null }>
                                <img
                                    alt = ''
                                    src = { '/icons/sort.svg' }
                                />
                            </div>
                        ) : ''}
                    </>
                );
            }

            return (
                <React.Fragment
                    key = { key }>
                    {resultJSx && (
                        <td className = { `${Styles[ key ] ? Styles[ key ] : key}` }>
                            <div className = { `${ Styles.filter }` }>{ resultJSx }</div>
                        </td>
                    )}
                </React.Fragment>
            );
        })
        : null;

    const listJSX = list && list.length > 0 ? list.map((item) => {
        return (
            <React.Fragment
                key = { item._id }>
                <ListItem
                    { ...props }
                    isParent
                    componentName = { componentName }
                    data = { item }
                    defaultItem = { defaultItem }
                    edit = { edit }
                    filter = { filter }
                    handleCheckboxChange = { handleCheckboxChange }
                    listSelects = { listSelects }
                    loading = { loading }
                    selectedDuplicates = { selectedDuplicates }
                    setEdit = { setEdit }
                    setIsAdd = { setIsAdd }
                    updateList = { updateList }
                    userData = { userData }
                />
                <Drawer
                    PaperProps = {{
                        sx: {
                            width:   '500px',  // Ширина Drawer
                            padding: '20px',  // Внутрішні відступи
                        },
                    }}
                    anchor = 'right'
                    open = { edit === item._id }
                    onClose = { () => setEdit(null) }>
                    <EditItem
                        { ...props }
                        isParent
                        componentName = { componentName }
                        data = { item }
                        defaultItem = { defaultItem }
                        edit = { edit }
                        filter = { filter }
                        handleCheckboxChange = { handleCheckboxChange }
                        listSelects = { listSelects }
                        selectedDuplicates = { selectedDuplicates }
                        setEdit = { setEdit }
                        setIsAdd = { setIsAdd }
                        updateList = { updateList }
                        userData = { userData }
                    />
                </Drawer>
            </React.Fragment>
        );
    }) : null;

    return (
        <>
            { moduleTitle && (
                <div className = { StylesHeader.header }>
                    <div className = { StylesHeader.title }>
                        { moduleTitle }
                    </div>
                    <div className = { `${StylesHeader.filter}` }>
                        <div className = { `${StylesForm.btns}` }>
                            { btns.length > 0 &&  btns.map((item, index)=>{
                                const Component = btnComponents[ item.component ] || null;

                                return (
                                    <React.Fragment key = { index }>
                                        { Component && (
                                            <Component
                                                { ...props }
                                                { ...item }
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                            {isShowAddBtn && userData.role === 'admin' && (
                                <div
                                    className = { `${StylesForm.button} ${StylesForm.short} ${ isAdd ? StylesForm.warning : '' }` }
                                    onClick = { () => {
                                        setIsAdd(!isAdd);
                                        setEdit('new');
                                    } }>
                                    <> <Add sx = { IconS } /> {`${translate[ titlesAdd[ componentName ] ] || componentName}`}</>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) }
            { tabs?.length > 0 && (
                <Tabs tabs = { tabs } />
            )}
            <div className = { StylesFilter.flex }>
                { isShowAddBtn || selectedAllTxt || selectedDuplicates.length > 1 ? (
                    <>
                        {isShowAddBtn && userData.role === 'admin' && !moduleTitle && (
                            <div className = { `${StylesForm.btns}` }>
                                <div
                                    className = { `${StylesForm.button} ${StylesForm.short} ${ isAdd ? StylesForm.warning : '' }` }
                                    onClick = { () => {
                                        setIsAdd(!isAdd);
                                        setEdit('new');
                                    } }>
                                    <> <Add sx = { IconS } /> { translate.ad5 }</>
                                </div>
                            </div>
                        )}
                        {selectedAllTxt && (
                            <div>
                                <Stack
                                    direction = 'row'
                                    spacing = { 2 }>
                                    <Button
                                        color = { status ? 'primary' : 'secondary' }
                                        variant = 'contained'
                                        onClick = { handleClickOpen }>
                                        {status ? 'Виключити ' + selectedAllTxt : 'Включити ' + selectedAllTxt}
                                    </Button>
                                    <Switch
                                        checked = { status }
                                        onChange = { handleClickOpen }
                                    />

                                    <Dialog
                                        open = { open }
                                        onClose = { () => handleClose(false) }>
                                        <DialogTitle>{"Підтвердіть дію"}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Ви впевнені, що хочете {status ? 'виключити' : 'включити'} {selectedAllTxt}?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                color = 'primary'
                                                onClick = { () => handleClose(false) }>
                                                Скасувати
                                            </Button>
                                            <Button
                                                autoFocus
                                                color = 'primary'
                                                onClick = { () => handleClose(true) }>
                                                Підтвердити
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Stack>
                            </div>
                        )}
                        {selectedDuplicates.length > 1 ? (
                            <Button
                                color = 'primary'
                                sx = {{ marginLeft: '10px' }}
                                variant = 'contained'
                                onClick = { handleMergeDuplicates }>
                                Merge Duplicates
                            </Button>
                        ) : ''}
                    </>
                ) : <></>}
                { filterJSX && (
                    <>
                        <div className = { StylesFilter.flex }>
                            <div>
                                <FilterSearch
                                    fields = { fields }
                                    setFilter = { setFilter }
                                    setFilterData = { setFilterData }
                                />
                            </div>
                            <div className = { StylesFilter.wrap }>
                                <div
                                    className = { StylesFilter.btnOutline }
                                    onClick = { () => setOpenDrawer(true) }>
                                    <svg
                                        fill = 'none'
                                        height = '16'
                                        viewBox = '0 0 16 16'
                                        width = '16'
                                        xmlns = 'http://www.w3.org/2000/svg'>
                                        <path
                                            clipRule = 'evenodd'
                                            d = 'M9.60579 15.268C9.09855 15.5679 8.47065 15.5777 7.95432 15.2937L6.43917 14.4604C5.90659 14.1674 5.5757 13.6078 5.5757 13L5.5757 7.75715L1.01138 3.36801C0.521202 2.89664 0.366564 2.17474 0.620647 1.54395C0.874731 0.91316 1.48657 0.5 2.16661 0.5L13.8333 0.5C14.5133 0.5 15.1252 0.91316 15.3792 1.54395C15.6333 2.17475 15.4787 2.89664 14.9885 3.36801L10.4242 7.75715L10.4242 13.8333C10.4242 14.4226 10.113 14.9681 9.60579 15.268ZM7.24237 13L8.75752 13.8333L8.75752 7.40239C8.75752 7.25218 8.79807 7.10627 8.87297 6.9792C8.9111 6.91452 8.95812 6.85472 9.01324 6.80172L13.8333 2.16667L2.16661 2.16667L6.98665 6.80172C7.04177 6.85472 7.08879 6.91452 7.12692 6.9792C7.20182 7.10627 7.24237 7.25218 7.24237 7.40239L7.24237 13Z'
                                            fill = '#272727'
                                            fillRule = 'evenodd'
                                        />
                                    </svg>
                                    { translate.bt4 }
                                    <span className = { StylesFilter.circle } >{Object.keys(filter).length}</span>
                                </div>
                                <div className = { StylesFilter.divider } />
                                <div
                                    className = { StylesFilter.clear }
                                    onClick = { () => clearFilter() }>
                                    { translate.bt5 }
                                </div>
                            </div>
                        </div>

                        <Drawer
                            PaperProps = {{
                                sx: {
                                    width:   '400px',  // Ширина Drawer
                                    padding: '20px',  // Внутрішні відступи
                                },
                            }}
                            anchor = 'right'
                            open = { openDrawer }
                            onClose = { () => setOpenDrawer(false) }>
                            <div className = { StylesFilter.drawerContent }>
                                <h2 className = { StylesFilter.h2 }>{ translate.bt4 }</h2>
                                <div className = { StylesFilter.filtersList }>
                                    {filterJSX}
                                </div>
                                <div className = { StylesFilter.drawerButtons }>
                                    <div
                                        className = { StylesFilter.btnOutline }
                                        onClick = { () => resetFilterData() }>
                                        { translate.bt1 }
                                    </div>
                                    <div
                                        className = { StylesFilter.btn }
                                        onClick = { () => setFilterData() }>
                                        { translate.bt2 }
                                    </div>
                                </div>
                            </div>
                        </Drawer>
                    </>
                ) }
            </div>
            <div className = { `${Styles.table} ${loading ? Styles.loading : ''}` }>

                { listJSX ? (
                    <table>
                        <thead>
                            <tr>
                                {headerJSX}
                                {isShowDuplicate ? (
                                    <th style = {{ width: '1%' }}>{ translate.bt6 }</th>
                                ) : ''}
                            </tr>
                        </thead>
                        <tbody>
                            {listJSX}
                        </tbody>
                    </table>

                ) : (
                    <div className = { `${Styles.nodata}` }>
                        <img
                            alt = ''
                            src = { '/images/nodata.svg' }
                        />
                        { translate.tx1 }
                    </div>
                ) }
                <Drawer
                    PaperProps = {{
                        sx: {
                            width:   '500px',  // Ширина Drawer
                            padding: '20px',  // Внутрішні відступи
                        },
                    }}
                    anchor = 'right'
                    open = { isAdd }
                    onClose = { () => setIsAdd(false) }>
                    <EditItem
                        { ...props }
                        componentName = { componentName }
                        data = { defaultItem }
                        defaultItem = { defaultItem }
                        edit = { edit }
                        filter = { filter }
                        isAdd = { isAdd }
                        key = { defaultItem._id }
                        listSelects = { listSelects }
                        setEdit = { setEdit }
                        setIsAdd = { setIsAdd }
                        setNewItem = { setNewItem }
                        updateList = { updateList }
                        userData = { userData }
                    />
                </Drawer>
            </div>

            {totalPagges  > 1 ? (
                <div>
                    <Pagination
                        currentPage = { page }
                        totalPages = { totalPagges }
                        onPageChange = { changePage }
                    />
                </div>
            ) : ''}
        </>
    );
}


export default ListConstructor;
