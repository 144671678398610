const listing = {};

listing.list = {
    _id: {
        type:  'text',
        title: 'id',
        width: '10',
    },
    name: {
        type:      'text',
        title:     'Name',
        width:     '10',
        component: 'Input',
        filter:    'Input',
    },
    description: {
        type:      'text',
        title:     'Опис',
        width:     '10',
        component: 'Input',
        filter:    'Input',
    },
    createdAt: {
        sort:  true,
        type:  'date',
        title: 'createdAt',
    },
    delete: {
        type:   'delete',
        title:  'Дія',
        values: [ 'rowEdit' ],

        width: '1',
    },
};

export { listing };
