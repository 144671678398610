import React from 'react';

function Users(props) {
    return (
        <svg
            className = { props.className }
            fill = 'none'
            height = '25'
            viewBox = '0 0 24 25'
            width = '24'
            xmlns = 'http://www.w3.org/2000/svg'>
            <path
                clipRule = 'evenodd'
                d = 'M5 4.27184C5 4.00223 5.22349 3.78343 5.5 3.78343H16.3368C16.1208 4.2279 16 4.72511 16 5.24991V19.5138C16 19.7121 15.8356 19.8728 15.6328 19.8728H5.5C5.22386 19.8728 5 19.654 5 19.384V11.8281V4.27184ZM19 1.82812V1.86278C19.1633 1.83994 19.3302 1.82812 19.5 1.82812C21.433 1.82812 23 3.36011 23 5.24991V7.64565C23 8.21232 22.5301 8.6717 21.9505 8.6717H18V19.5138C18 20.792 16.9402 21.8281 15.6328 21.8281H5.5C4.11929 21.8281 3 20.7338 3 19.384V11.8281V4.27184C3 2.92163 4.11966 1.82812 5.5 1.82812H19ZM21 5.24991V6.71639H18V5.24991C18 4.44 18.6716 3.78343 19.5 3.78343C20.3284 3.78343 21 4.44 21 5.24991ZM11.4998 7.69405C11.4998 7.1541 11.052 6.71639 10.4998 6.71639C9.94747 6.71639 9.49976 7.1541 9.49976 7.69405V8.0424C8.36915 8.20037 7.5 9.15093 7.5 10.2999C7.5 11.5595 8.5444 12.5805 9.83273 12.5805H11.1691C11.3529 12.5805 11.5018 12.7262 11.5018 12.9058C11.5018 13.0855 11.3529 13.2311 11.1691 13.2311H8.83349C8.2812 13.2311 7.83349 13.6689 7.83349 14.2088C7.83349 14.7487 8.2812 15.1864 8.83349 15.1864H9.49976V15.5117C9.49976 16.0517 9.94747 16.4894 10.4998 16.4894C11.052 16.4894 11.4998 16.0517 11.4998 15.5117V15.1637C12.6315 15.0067 13.5018 14.0556 13.5018 12.9058C13.5018 11.6463 12.4574 10.6252 11.1691 10.6252H9.83273C9.64897 10.6252 9.5 10.4796 9.5 10.2999C9.5 10.1203 9.64897 9.97465 9.83273 9.97465H12.1683C12.7206 9.97465 13.1683 9.53694 13.1683 8.997C13.1683 8.45705 12.7206 8.01934 12.1683 8.01934H11.4998V7.69405Z'
                fill = 'black'
                fillRule = 'evenodd'
            />
        </svg>


    );
}

export default Users;
