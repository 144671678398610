import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiActions } from '../../../bus/actions';
import * as Yup from 'yup';
import { api } from '../../../Api';
import { saveSession } from '../../../instruments';
import Styles from './styles.module.scss';
import StylesForm from '../../../theme/styles/form.module.scss';
import Info from '../../Icons/Info';
import { langs } from '../../../instruments/lang';

const selectState = (state) => ({
    isLogged:  state.swapi.isLogged,
    userData:  state.swapi.userData,
    translate: state.swapi.translate,
});

const SignupSchema = Yup.object().shape({
    username: Yup.string()
        .min(2, 'errors_toShort')
        .max(30, 'errors_toLong')
        .required('errors_required'),
    password: Yup.string()
        .min(2, 'errors_toShort')
        .max(30, 'errors_toLong')
        .required('errors_required'),
});

function Login() {
    const [ isSend, setIsSend ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ showPassw, setShowPassw ] = useState(false);
    const dispatch = useDispatch();
    const state = useSelector(selectState);
    const isLogged = state.isLogged;
    const userData = state.userData;
    const translate = state.translate;
    const navigate = useNavigate();

    useEffect(() => {
        if (isLogged) {
            let url = '/';
            if (userData.role !== 'client') {
                url = '/admin/users';
            }
            navigate(url);
        }
        // eslint-disable-next-line
    }, [ isLogged ]);

    const setLogin = async (values) => {
        setIsSend(true);
        const data = await api.postData(values, '/login');
        if (data && data.token) {
            saveSession(data);
            dispatch(apiActions.setIsLogged(true));
            dispatch(apiActions.setUserData(data));
            if (data.company_id && data.company_id[ 0 ]?._id) {
                dispatch(apiActions.setCompany(data.company_id[ 0 ]));
            }
            if (data.lang && langs[ data.lang ]) {
                dispatch(apiActions.setLang(data.lang));
                dispatch(apiActions.setTranslate(langs[ data.lang ]));
            }
            if (window.location.pathname === '/login') {
                let url = '/';
                if (data.role !== 'client') {
                    url = '/admin/users';
                }
                navigate(url);
            }
        } else if (data && data.error) {
            setError(data.error);
            setIsSend(false);
        }
    };


    return (
        <div className = { `${Styles.wrap}` }>
            <div className = { `${Styles.left}` }>
                <div className = { Styles.logo }>
                    <img
                        alt = ''
                        src = { '/images/logo2.svg' }
                    />
                </div>
            </div>
            <div className = { `${Styles.right}` }>
                <div className = { `${Styles.formWrap}` }>
                    <div className = { `${Styles.titles}` }>
                        <div className = { `${Styles.title}` }>
                            { translate.l1 }
                        </div>
                        <div className = { `${Styles.sub}` }>
                            { translate.l2 }
                        </div>
                    </div>
                    <Formik
                        initialValues = {{
                            username: '',
                            password: '',
                        }}
                        validationSchema = { SignupSchema }
                        onSubmit = { setLogin }>
                        {(formikProps) => (
                            <form onSubmit = { formikProps.handleSubmit }>
                                <div className = { `${StylesForm.field}` }>
                                    <div className = { `${StylesForm.label}` }>
                                        { translate.l3 }
                                    </div>
                                    <Field
                                        fullWidth
                                        required
                                        autoComplete = 'username'
                                        className = { `${StylesForm.input} ${error === 'not_found' ? StylesForm.error : ''}` }
                                        id = 'username'
                                        label = 'Username/Email'
                                        name = 'username'
                                        placeholder = 'm@example.com'
                                        variant = 'outlined'
                                    />
                                    {error === 'not_found' && (
                                        <div className = { `${StylesForm.error}` }>
                                            <Info className = { `${StylesForm.errorIcon}` }/>
                                            { translate.l5 }
                                        </div>
                                    )}
                                </div>
                                <div className = { `${StylesForm.field}` }>
                                    <div className = { `${StylesForm.label}` }>
                                        { translate.l4 }
                                    </div>
                                    <Field
                                        fullWidth
                                        required
                                        autoComplete = 'current-password'
                                        className = { `${StylesForm.input} ${error === 'wrong_password' ? StylesForm.error : ''}` }
                                        id = 'password'
                                        label = 'Password'
                                        name = 'password'
                                        type = { showPassw ? 'text' : 'password' }
                                        variant = 'outlined'
                                    />
                                    <div
                                        className = { `${StylesForm.eye}` }
                                        onClick = { ()=>setShowPassw(!showPassw) }>
                                        <img
                                            alt = ''
                                            src = { '/icons/eye-off.svg' }
                                        />
                                    </div>
                                    {error === 'wrong_password' && (
                                        <div className = { `${StylesForm.error}` }>
                                            <Info className = { `${StylesForm.errorIcon}` }/>
                                            { translate.l6 }
                                        </div>
                                    )}
                                </div>
                                <div className = { `${StylesForm.link}` }>
                                    { translate.l7 }
                                </div>
                                <div>
                                    <button
                                        className = { `${StylesForm.button}` }
                                        disabled = { isSend }
                                        type = 'submit'>
                                        { translate.l8 }
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default Login;
