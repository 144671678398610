import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UserMenu from './userMenu';
import Clock from './clock';
import Reminder from './reminder';
import Balance from './balance';
import Styles from './header.module.scss';
import Switcher from './switcher';

const selectState = (state) => ({
    isLogged: state.swapi.isLogged,
    userData: state.swapi.userData,
});

function HeaderLine() {
    const state = useSelector(selectState);
    const userData = state.userData;
    const [ open, setOpen ] = useState(false);

    return (
        <>
            <div className = { Styles.wrapMobile }>
                <div className = { Styles.logo }>
                    <img
                        alt = ''
                        className = { Styles.m_icon }
                        src = { '/images/logo.svg' }
                    />
                </div>
                <div
                    className = { Styles.burger }
                    onClick = { ()=>setOpen(true) }>
                    <svg
                        fill = 'none'
                        height = '24'
                        viewBox = '0 0 24 24'
                        width = '24'
                        xmlns = 'http://www.w3.org/2000/svg'>
                        <path
                            clipRule = 'evenodd'
                            d = 'M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6ZM3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12ZM4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17H4Z'
                            fill = '#272727'
                            fillRule = 'evenodd'
                        />
                    </svg>

                </div>
            </div>
            <div className = { `${Styles.wrap} ${ open ? Styles.open : '' }` }>
                <div className = { Styles.topMobile }>
                    <div className = { Styles.logo }>
                        <img
                            alt = ''
                            className = { Styles.m_icon }
                            src = { '/images/logo1.svg' }
                        />
                    </div>
                    <div
                        className = { Styles.close }
                        onClick = { ()=>setOpen(false) }>
                        <svg
                            fill = 'none'
                            height = '24'
                            viewBox = '0 0 24 24'
                            width = '24'
                            xmlns = 'http://www.w3.org/2000/svg'>
                            <path
                                clipRule = 'evenodd'
                                d = 'M20.7071 4.70711C21.0976 4.31658 21.0976 3.68342 20.7071 3.29289C20.3166 2.90237 19.6834 2.90237 19.2929 3.29289L12 10.5858L4.70711 3.29289C4.31658 2.90237 3.68342 2.90237 3.29289 3.29289C2.90237 3.68342 2.90237 4.31658 3.29289 4.70711L10.5858 12L3.29289 19.2929C2.90237 19.6834 2.90237 20.3166 3.29289 20.7071C3.68342 21.0976 4.31658 21.0976 4.70711 20.7071L12 13.4142L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L13.4142 12L20.7071 4.70711Z'
                                fill = 'black'
                                fillRule = 'evenodd'
                            />
                        </svg>
                    </div>
                </div>
                <Clock />
                <div className = { Styles.item }>
                    <Switcher />
                    { userData && (
                        <>
                            <Balance />
                            <Reminder userData = { userData } />
                            <UserMenu
                                userData = { userData }
                            />
                        </>
                    )}
                </div>
            </div>
        </>

    );
}
export default HeaderLine;
