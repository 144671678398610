import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../../instruments';
import AccountCompany from '../Company';

export const CompanyRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <AccountCompany
                        { ...props }
                    />
                }
                path = { book.admin.company }
            />

        </Routes>
    );
};
