/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import moment from 'moment';

function Reminder(props) {
    const { userData } = props;
    const [ list, setList ] = useState([]);
    useEffect(() => {
        if (userData && userData.role === 'client' && userData.company_id?.length > 0) {
            const _list = [];
            const now = moment().toISOString();
            const time = moment().add(2, 'days')
                .toISOString();
            for (const company_id of userData.company_id) {
                if (company_id?.service?.length > 0) {
                    for (const serv of company_id.service) {
                        if (moment(serv.next_pay).toISOString()  > now && moment(serv.next_pay).toISOString() <= time) {
                            _list.push({
                                name: serv.comment,
                                date: serv.next_pay,
                            });
                        }
                    }
                }
            }
            setList(_list);
        } else {
            setList([]);
        }
    }, [ userData ]);

    const listJSX = list.slice(0, 2).map((item, key)=>{
        return (
            <div key = { key }>{ `Наступне списання за ${item.name} - ${moment(item.date).format('DD.MM.YY')}` }</div>
        );
    });

    return (
        <div>
            { listJSX }
        </div>
    );
}
export default Reminder;
