import React, { useState, useEffect, useRef } from 'react';
import { api } from '../../../Api';
import debounce from 'lodash.debounce'; // Імпорт debounce
import Styles from './styles/Search.module.scss';

export const Search = (props) => {
    const { valueField = '', nameField, setFieldValue, type = 'author', placeholder = '',
        multi = false, clear = false, dataSearch, selectedList, removeField } = props;
    const [ list, setList ] = useState([]);
    const [ val, setVal ] = useState(valueField);
    const [ isOpen, setIsOpen ] = useState(false);
    const searchRef = useRef(null);

    const setValue = (item) => {
        const name = multi ? item.label : nameField;
        if (removeField && multi) {
            const active = selectedList?.length > 0
                ? selectedList.find((el)=>el.value === item.value) : null;
            if (active) {
                removeField(name, item.value);
            } else {
                setFieldValue(name, item.value);
            }
        } else {
            setFieldValue(name, item.value);
        }
        if (multi) {
            setVal('');
        } else {
            setVal(item.label);
        }
        setList([]);
        setIsOpen(false);
    };

    const getData = async (value) => {
        const param = {
            search: value,
            type:   type,
            ...dataSearch,
        };
        const data = await api.postData(param, '/getListSearch');
        if (data) {
            setList(data);
            setIsOpen(true);
        }
    };

    useEffect(() => {
        if (!valueField) {
            setVal('');
        }
    }, [ valueField ]);

    useEffect(() => {
        if (clear) {
            setVal('');
        }
    }, [ clear ]);

    const handleChange = (event) => {
        const inputValue = event.target.value;
        setVal(inputValue);
    };

    const debouncedGetData = useRef(debounce((value) => {
        if (value.length >= 2) {
            getData(value);
        }
    }, 400)).current; // Використовуємо debounce для відправки запиту з затримкою

    useEffect(() => {
        if (val !== valueField && isOpen) {
            debouncedGetData(val); // Викликаємо debouncedGetData замість getData
        }
    }, [ val ]);

    const handleClick = () => {
        if (isOpen) {
            setIsOpen(false);
        } else {
            getData('');
        }
    };

    const handleListClick = (item) => () => {
        setValue(item);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const listJSX = isOpen && list && list.length > 0 ? (
        <div className = { Styles.list }>
            {list.map((item, index) => {
                const active = selectedList?.length > 0
                    ? selectedList.find((el)=>el.value === item.value) : null;

                return (
                    <div
                        className = { `${Styles.item} ${active ? Styles.active : ''}` }
                        key = { index }
                        onClick = { handleListClick(item) }
                        onMouseEnter = { () => {
                            const updatedList = [ ...list ];
                            updatedList[ index ].hover = true;
                            setList(updatedList);
                        } }
                        onMouseLeave = { () => {
                            const updatedList = [ ...list ];
                            updatedList[ index ].hover = false;
                            setList(updatedList);
                        } }>
                        <div className = { Styles.checkbox }>
                            <svg
                                fill = 'none'
                                height = '9'
                                viewBox = '0 0 12 9'
                                width = '12'
                                xmlns = 'http://www.w3.org/2000/svg'>
                                <path
                                    d = 'M11.3333 1L3.99996 8.33333L0.666626 5'
                                    stroke = 'white'
                                    strokeLinecap = 'round'
                                    strokeLinejoin = 'round'
                                    strokeWidth = '1.25'
                                />
                            </svg>
                        </div>

                        {item.label}
                    </div>
                );
            })}
        </div>
    ) : null;

    return (
        <div
            className = { Styles.wrap }
            ref = { searchRef }>
            <div
                className = { `${Styles.input} ${isOpen ? Styles.open : ''} ` }
                onClick = { handleClick }>
                { `${placeholder}` }
                <div
                    className = { Styles.icon }>
                    <img
                        alt = ''
                        src = { '/icons/Down.svg' }
                    />
                </div>
            </div>
            <div className = { `${Styles.listWrap} ${isOpen ? Styles.open : ''} ` }>
                <div className = { Styles.search }>
                    <img
                        alt = ''
                        src = { '/icons/Search.svg' }
                    />
                    <input
                        placeholder = { 'Search' }
                        type = 'text'
                        value = {  val }
                        onChange = { handleChange }
                    />
                </div>
                {listJSX}
            </div>
        </div>
    );
};
