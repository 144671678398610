import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './switcher.module.scss';
import { langs } from '../../instruments/lang';
import { apiActions } from '../../bus/actions';
import { saveSession, setCookieLang } from '../../instruments';
import { api } from '../../Api';

const selectState = (state) => ({
    translate: state.swapi.translate,
    userData:  state.swapi.userData,
});

function Switcher() {
    const state = useSelector(selectState);
    const translate = state.translate;
    const userData = state.userData;
    const dispatch = useDispatch();
    const [ open, setOpen ] = useState(false);

    const setLang = async (code) => {
        if (code && langs[ code ]) {
            setCookieLang(code);
            dispatch(apiActions.setLang(code));
            dispatch(apiActions.setTranslate(langs[ code ]));
            const props = {
                lang: code,
            };
            const data = await api.postData(props, '/setLang');
            if (data && data.status) {
                const user = {
                    ...userData,
                    lang: code,
                };
                dispatch(apiActions.setUserData(user));
                saveSession(user);
            }
        }
    };

    return (
        <div
            className = { `${Styles.wrap} ${open ? Styles.open : ''}` }
            onClick = { ()=>setOpen(!open) }>
            <div
                className = { Styles.icon }>
                <img
                    alt = ''
                    src = { '/icons/Down.svg' }
                />
            </div>
            { translate.title }
            <div className = { Styles.list }>
                <div
                    className = { Styles.item }
                    onClick = { ()=>setLang('en') }>
                    { 'English' }
                </div>
                <div
                    className = { Styles.item }
                    onClick = { ()=>setLang('ua') }>
                    { 'Українська' }
                </div>
                <div
                    className = { Styles.item }
                    onClick = { ()=>setLang('ru') }>
                    { 'Русский' }
                </div>
            </div>
        </div>
    );
}
export default Switcher;
