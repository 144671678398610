import React, { useEffect, useState } from 'react';
import AccountserviceCompany from '../Account/serviceCompany';
import { useSelector } from 'react-redux';

const selectState = (state) => ({
    userData: state.swapi.userData,
    company:  state.swapi.company,
});

function Home () {
    const state = useSelector(selectState);
    const  userData  = state.userData;
    const [ company_id, setCompanyId ] = useState('');

    const company = state.company;

    useEffect(() => {
        if (userData.role === 'client' && company?._id) {
            setCompanyId(company?._id);
        }
    }, [ company, userData ]);

    return company_id && (
        <>
            <AccountserviceCompany company_id = { company_id } />
        </>
    );
}

export default Home;
