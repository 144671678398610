import React from 'react';

function Company(props) {
    return (
        <svg
            className = { props.className }
            fill = 'none'
            height = '20'
            viewBox = '0 0 20 20'
            width = '20'
            xmlns = 'http://www.w3.org/2000/svg'>
            <path
                clipRule = 'evenodd'
                d = 'M4.99984 7.5L4.1665 7.5C3.70627 7.5 3.33317 7.8731 3.33317 8.33333L3.33317 15C3.33317 15.4602 3.70627 15.8333 4.1665 15.8333H4.99984V7.5ZM13.3332 15.8333V7.5H11.6665L8.33317 7.5H6.6665V15.8333L13.3332 15.8333ZM15.8332 15.8333H14.9998V7.5L15.8332 7.5C16.2934 7.5 16.6665 7.8731 16.6665 8.33333V15C16.6665 15.4602 16.2934 15.8333 15.8332 15.8333ZM13.3332 5.83333L15.8332 5.83333C17.2139 5.83333 18.3332 6.95262 18.3332 8.33333V15C18.3332 16.3807 17.2139 17.5 15.8332 17.5L4.1665 17.5C2.78579 17.5 1.6665 16.3807 1.6665 15V8.33333C1.6665 6.95262 2.78579 5.83333 4.1665 5.83333H6.6665V5C6.6665 3.61929 7.78579 2.5 9.1665 2.5H10.8332C12.2139 2.5 13.3332 3.61929 13.3332 5V5.83333ZM11.6665 5.83333H10.8332H9.99984L9.1665 5.83333H8.33317V5C8.33317 4.53996 8.70595 4.16698 9.16592 4.16667L9.1665 4.16667L10.8332 4.16667L10.8338 4.16667C11.2937 4.16698 11.6665 4.53996 11.6665 5V5.83333Z'
                fillRule = 'evenodd'
            />

        </svg>
    );
}

export default Company;
