// Types
export const types = {
    FETCH_CONTENT: 'FETCH_CONTENT',
    SET_IS_LOGGED: 'SET_IS_LOGGED',
    SET_USER_DATA: 'SET_USER_DATA',
    SET_COMPANY:   'SET_COMPANY',
    SET_LANG:      'SET_LANG',
    SET_TRANSLATE: 'SET_TRANSLATE',

};
