export const ru = {
    code:  'ru',
    title: 'Русский',
    l1:    'Поздравляем с возвращением!',
    l2:    'Введите электронную почту и пароль для входа в свой аккаунт.',
    l3:    'Email',
    l4:    'Пароль',
    l5:    'Пользователь не найден',
    l6:    'Некорректный пароль',
    l7:    'Забыли пароль?',
    l8:    'Логин',
    cl1:   'Ваше время',
    cl2:   'Рабочее время',
    hb1:   'Дополнительный баланс',
    hb2:   'Мой баланс',
    so:    'Выйти',
    mn1:   'Пользователи',
    mn2:   'Компании',
    mn3:   'Номера',
    mn4:   'Статистика звонков',
    mn5:   'Услуги',
    mn6:   'Поставщики',
    mn7:   'Аналитика',
    mn8:   'Баланс услуг',
    mn9:   'Настройки',
    sb1:   'Скрыть панель',
    sb2:   'Раскрыть панель',
    ad1:   'Добавить пользователя',
    ad2:   'Добавить компанию',
    ad3:   'Добавить номер',
    ad4:   'Добавить поставщика',
    ad5:   'Добавить',
    ed1:   'Редактировать пользователя',
    ed2:   'Редактировать компанию',
    ed3:   'Редактировать номер',
    ed4:   'Редактировать поставщика',
    bt1:   'Вернуться назад',
    bt2:   'Сохранить',
    bt3:   'Поиск',
    bt4:   'Фильтры',
    bt5:   'Сбросить фильтры',
    bt6:   'Объединить',
    bt7:   'Подключить номера компаний',
    bt8:   'Загрузить номера поставщиков',
    bt9:   'Отключить номера',
    bt10:  'Скачать',
    bt12:  'Дополнительные услуги',
    pg1:   'Перейти к',
    tx1:   'Пока здесь ничего, но это ненадолго! 😉',
    tx2:   'Все',
    tx3:   'в прошлом месяце',
    tx4:   'Вместе',
    tx5:   'Страна',
    tx6:   'страны',
    tx7:   'Активный',
    tx8:   'Неактивный',
    t1:    'Статистика звонков',
    t2:    'Статистика регионов',
    t3:    'Мои номера',
    t4:    'Дополнительные услуги',
    t5:    'Список',
    t6:    'Аналитика',
    st1:   '% Дозвонов',
    st2:   'AСД',
    st3:   'Звонки',
    st4:   'Расходы',
    st5:   'Информация о сеансах.',
    st6:   'Время в звонке',
    st7:   'Расходы по геолокации',
    st8:   'Общая аналитика',
    st9:   'Диаграмма',
    st10:  'Динамика',
    f1:    'Имя пользователя',
    f2:    'Телеграмм ID',
    f3:    'Роль',
    f4:    'Компания',
    f5:    'Процент представителя',
    f6:    'Процент партнера',
    f7:    'Пароль',
    f8:    'Email',
    f9:    'Действие',
    f10:   'Компания',
    f11:   'Мор',
    f12:   'АТС',
    f13:   'Менеджер',
    f14:   'Кредитный лимит',
    f15:   'Список Мор',
    f16:   'Дата платежа',
    f17:   'Баланс услуг',
    f18:   'Партнер',
    f19:   'Страна',
    f20:   'Телефон',
    f21:   'Тип',
    f22:   'Поставщик',
    f23:   'Цена п-ка',
    f24:   'Абонплата п-ка',
    f25:   'Цена для компании',
    f26:   'Абонплата для компании',
    f27:   'Удаление поставщика',
    f28:   'Резерв',
    f29:   'Дата создания',
    f30:   'Дата',
    f31:   'Номер',
    f32:   'Звонок в',
    f33:   'Длительность',
    f34:   'Статус',
    f35:   'Причина зависания',
    f36:   'Сервер',
    f37:   'Прибыль',
    f38:   'Дата подключения',
    f39:   'Дата списания',
    f40:   'Стоимость подключения',
    f41:   'Абонплата за номер',
    f42:   'Название',
    f43:   'Абонплата',
    f44:   'Баланс',
    f45:   'Списание абон. при подключении',
    f46:   'Представитель',
    f47:   'Процент представителя',
    f49:   'Комментарий',
    f50:   'Сумма',
    f51:   'Услуга',
    f52:   'Сумма транзакции',
    f53:   'Сумма закупки',
    f54:   'Премия менеджера',
    f55:   'Премия сюр',
    f56:   'Премия партнера',
    f57:   'Списано/пополнено',
    f58:   'Остаток',
    f59:   'Описание',
    f60:   'На Удаление',
    f61:   'Проверка на спам',
    f62:   'СМС',
    f63:   'Общий прибыль',
    f65:   'Прибыль по звонкам',
    f66:   'Прибыль по номерам',
    f67:   'Прибыль по АТС',
    f68:   'Прибыль по смс',
    f69:   'Прибыль по проверке на спам',
    f70:   'Інформация',

};
