export const setTraslate = (list, translate) => {
    const result = {};
    for (const key of Object.keys(list)) {
        result[ key ] = { ...list[ key ] };
        if (result[ key ].title && translate[ result[ key ].title ]) {
            result[ key ].title = translate[ result[ key ].title ];
        }
    }

    return result;
};
