import React from 'react';

function Postman(props) {
    return (
        <svg
            className = { props.className }
            fill = 'none'
            height = '16'
            viewBox = '0 0 18 16'
            width = '18'
            xmlns = 'http://www.w3.org/2000/svg'>
            <path
                clipRule = 'evenodd'
                d = 'M1.49996 0.5C1.03972 0.5 0.666626 0.873096 0.666626 1.33333C0.666626 1.79357 1.03972 2.16667 1.49996 2.16667V1.33333L1.49997 2.16667H1.5H1.50014H1.50024H1.50036H1.5005H1.50067H1.50155H1.50277H1.50351H1.50391H1.50434H1.50624H1.50848H1.50909H1.50972H1.51105H1.54309H1.66272H2.07288H2.50144L4.43735 10.6847C4.53716 11.1239 4.96689 11.4025 5.40583 11.3186C5.45656 11.3283 5.50892 11.3333 5.56246 11.3333H14C14.3473 11.3333 14.6583 11.1179 14.7802 10.7926L17.2802 4.12594C17.3762 3.87002 17.3406 3.58332 17.1849 3.35867C17.0292 3.13403 16.7733 3 16.5 3H4.4L3.97924 1.14865C3.89301 0.769242 3.55571 0.5 3.16663 0.5H2.07288H1.66272H1.54309H1.538H1.53322H1.53207H1.53094H1.52874H1.52456H1.51717H1.51633H1.51551H1.51472H1.51394H1.51319H1.51246H1.51105H1.50624H1.50434H1.50391H1.50351H1.50277H1.50067H1.50036H1.50024H1.50014H1.5H1.49997L1.49996 1.33333V0.5ZM4.77879 4.66667L5.91515 9.66667H13.4225L15.2975 4.66667H4.77879ZM7.33329 12.1667C6.41282 12.1667 5.66663 12.9129 5.66663 13.8333C5.66663 14.7538 6.41282 15.5 7.33329 15.5C8.25377 15.5 8.99996 14.7538 8.99996 13.8333C8.99996 12.9129 8.25377 12.1667 7.33329 12.1667ZM10.6666 13.8333C10.6666 12.9129 11.4128 12.1667 12.3333 12.1667C13.2538 12.1667 14 12.9129 14 13.8333C14 14.7538 13.2538 15.5 12.3333 15.5C11.4128 15.5 10.6666 14.7538 10.6666 13.8333Z'
                fillRule = 'evenodd'
            />
        </svg>
    );
}

export default Postman;
