import React from 'react';

function Phone(props) {
    return (
        <svg
            className = { props.className }
            fill = 'none'
            height = '18'
            viewBox = '0 0 18 18'
            width = '18'
            xmlns = 'http://www.w3.org/2000/svg'>
            <path
                clipRule = 'evenodd'
                d = 'M4.2531 2.33156C4.25312 2.33155 4.25332 2.33177 4.25366 2.33227L4.2531 2.33156ZM4.28899 2.38847L5.96171 5.04999C5.75958 5.24162 5.5391 5.43143 5.30182 5.63571C5.09788 5.81129 4.88153 5.99755 4.65374 6.20484C4.08917 6.71861 3.98012 7.54491 4.34551 8.18797C6.27768 11.5885 8.91302 13.1358 10.0922 13.6913C10.8026 14.026 11.5745 13.7619 12.0041 13.2149L13.1616 11.7415C13.1624 11.7406 13.1623 11.7409 13.1618 11.7411C13.1618 11.7412 13.1626 11.7407 13.1644 11.7404C13.1661 11.7401 13.1679 11.7401 13.1693 11.7404C13.1703 11.7406 13.1718 11.741 13.1742 11.7425C13.8487 12.1588 14.7897 12.7245 15.6541 13.2034C15.4989 14.1407 15.1423 14.6866 14.7491 15.0204C14.3362 15.3709 13.8023 15.5554 13.2068 15.622C12.0177 15.7549 10.8107 15.3942 10.5429 15.3063C10.2475 15.1553 8.62792 14.3028 6.8976 12.9213C5.11337 11.4967 3.32427 9.59816 2.64003 7.42072C2.13996 5.82937 2.32853 4.70955 2.72157 3.93712C3.10152 3.1904 3.71639 2.68358 4.28899 2.38847ZM3.68389 0.813263C4.47216 0.439385 5.28727 0.829205 5.66694 1.43331L7.41684 4.21765C7.77061 4.78054 7.79487 5.60342 7.21974 6.16711C6.97326 6.40869 6.63033 6.70562 6.31122 6.98193C6.13606 7.13359 5.96807 7.27905 5.82714 7.40573C7.49007 10.3014 9.71329 11.641 10.7245 12.1309L11.8501 10.6981C12.3815 10.0216 13.3278 9.86353 14.0483 10.3083C14.7149 10.7197 15.6365 11.2734 16.4716 11.7357C17.0286 12.0441 17.427 12.687 17.3141 13.4128C17.1114 14.7163 16.5818 15.6658 15.8271 16.3064C15.0851 16.9364 14.2012 17.2036 13.3914 17.2942C11.7987 17.4723 10.2754 16.99 9.9972 16.8968C9.92677 16.8733 9.86832 16.8484 9.80549 16.8165C9.50685 16.6647 7.74688 15.7471 5.85843 14.2393C3.98962 12.7472 1.88205 10.5888 1.04591 7.928C0.431718 5.97349 0.613407 4.38872 1.23339 3.17029C1.8425 1.97322 2.82892 1.21877 3.68389 0.813263Z'
                fillRule = 'evenodd'
            />
        </svg>


    );
}

export default Phone;
