import React from 'react';

function ServiceBalance(props) {
    return (
        <svg
            className = { props.className }
            fill = 'none'
            height = '12'
            viewBox = '0 0 18 12'
            width = '18'
            xmlns = 'http://www.w3.org/2000/svg'>
            <path
                clipRule = 'evenodd'
                d = 'M14.8334 11.8337L3.16675 11.8337C1.78604 11.8337 0.666749 10.7144 0.666749 9.33371L0.666749 4.33371L0.666748 2.66704C0.666748 1.28633 1.78604 0.167039 3.16675 0.167039L14.8334 0.167038C16.2141 0.167038 17.3334 1.28633 17.3334 2.66704V9.3337C17.3334 10.7144 16.2141 11.8337 14.8334 11.8337ZM2.33341 3.50037V2.66704C2.33341 2.2068 2.70651 1.83371 3.16675 1.83371L14.8334 1.8337C15.2937 1.8337 15.6667 2.2068 15.6667 2.66704V3.50037H2.33341ZM2.33342 5.16704H15.6667V9.3337C15.6667 9.79394 15.2937 10.167 14.8334 10.167L3.16675 10.167C2.70651 10.167 2.33342 9.79394 2.33342 9.33371L2.33342 5.16704ZM3.16675 7.66704C3.16675 7.2068 3.53984 6.83371 4.00008 6.83371H7.33342C7.79365 6.83371 8.16675 7.2068 8.16675 7.66704C8.16675 8.12728 7.79365 8.50037 7.33342 8.50037H4.00008C3.53984 8.50037 3.16675 8.12728 3.16675 7.66704Z'
                fillRule = 'evenodd'
            />
        </svg>

    );
}

export default ServiceBalance;
