import React from 'react';

function Info(props) {
    return (
        <svg
            className = { props.className }
            fill = 'none'
            height = '16'
            viewBox = '0 0 16 16'
            width = '16'
            xmlns = 'http://www.w3.org/2000/svg'>
            <path
                d = 'M7.33325 10.0002H8.66659V11.3335H7.33325V10.0002ZM7.33325 4.66683H8.66659V8.66683H7.33325V4.66683ZM7.99325 1.3335C4.31325 1.3335 1.33325 4.32016 1.33325 8.00016C1.33325 11.6802 4.31325 14.6668 7.99325 14.6668C11.6799 14.6668 14.6666 11.6802 14.6666 8.00016C14.6666 4.32016 11.6799 1.3335 7.99325 1.3335ZM7.99992 13.3335C5.05325 13.3335 2.66659 10.9468 2.66659 8.00016C2.66659 5.0535 5.05325 2.66683 7.99992 2.66683C10.9466 2.66683 13.3333 5.0535 13.3333 8.00016C13.3333 10.9468 10.9466 13.3335 7.99992 13.3335Z'
            />
        </svg>
    );
}

export default Info;
