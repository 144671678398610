import React from 'react';

function Analytics(props) {
    return (
        <svg
            className = { props.className }
            fill = 'none'
            height = '18'
            viewBox = '0 0 18 18'
            width = '18'
            xmlns = 'http://www.w3.org/2000/svg'>
            <path
                clipRule = 'evenodd'
                d = 'M14.8334 0.666626H3.16671C1.786 0.666626 0.666708 1.78591 0.666708 3.16663V14.8333C0.666708 16.214 1.786 17.3333 3.16671 17.3333H14.8334C16.2141 17.3333 17.3334 16.214 17.3334 14.8333V3.16663C17.3334 1.78591 16.2141 0.666626 14.8334 0.666626ZM2.33337 3.16663C2.33337 2.70639 2.70647 2.33329 3.16671 2.33329H14.8334C15.2936 2.33329 15.6667 2.70639 15.6667 3.16663V14.8333C15.6667 15.2935 15.2936 15.6666 14.8334 15.6666H3.16671C2.70647 15.6666 2.33337 15.2935 2.33337 14.8333V3.16663ZM12.75 3.99996C12.0597 3.99996 11.5 4.5596 11.5 5.24996V12.75C11.5 13.4403 12.0597 14 12.75 14C13.4404 14 14 13.4403 14 12.75V5.24996C14 4.5596 13.4404 3.99996 12.75 3.99996ZM7.75004 8.58329C7.75004 7.89294 8.30969 7.33329 9.00004 7.33329C9.6904 7.33329 10.25 7.89294 10.25 8.58329V12.75C10.25 13.4403 9.6904 14 9.00004 14C8.30968 14 7.75004 13.4403 7.75004 12.75V8.58329ZM5.25004 10.6666C4.55968 10.6666 4.00004 11.2263 4.00004 11.9166V12.75C4.00004 13.4403 4.55968 14 5.25004 14C5.9404 14 6.50004 13.4403 6.50004 12.75V11.9166C6.50004 11.2263 5.9404 10.6666 5.25004 10.6666Z'
                fillRule = 'evenodd'
            />
        </svg>
    );
}

export default Analytics;
