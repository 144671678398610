import React, { useState, useEffect } from 'react';
import Styles from './styles/select.module.scss';
import StylesForm from '../../../theme/styles/form.module.scss';

export const Select = (props) => {
    const {
        valueField,
        nameField,
        list,
        setFieldValue,
        label,
        placeholder,
        setDefaultFilter,
        clear = false,
        isFilter,
        labelTxt = '',
    } = props;

    const [ value, setValue ] = useState(valueField || '');
    const [ listOpt, setList ] = useState(list || '');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ _placeholder, setPlaceholder ] = useState(label || placeholder);

    const handleChange = (el) => {
        setValue(el.value);
        if (!el.value && typeof setDefaultFilter === 'function') {
            setDefaultFilter(false);
        }
        setFieldValue(nameField, el.value);
        if (el.value) {
            setPlaceholder(el.label);
        } else {
            setPlaceholder(label || placeholder);
        }
        setIsOpen(false);
    };
    useEffect(() => {
        if (clear) {
            setValue('');
            setPlaceholder(label || placeholder);
        }
    }, [ clear ]);
    useEffect(() => {
        if (valueField === '' && value !== '') {
            setValue('');
        } else {
            const item = listOpt ? listOpt.find((el)=>el.value === valueField.toString()) : null;
            if (item) {
                handleChange(item);
            }
        }
    }, [ valueField, listOpt ]);


    useEffect(() => {
        setList(list);
    }, [ list ]);

    const optionJSX = listOpt && listOpt.map((el) => {
        let show = true;
        if (el.isFilter) {
            show =  !!isFilter;
        }

        return (
            <React.Fragment key = { el.value }>
                { el.label && show ? (
                    <div
                        className = { `${Styles.item} ${isOpen ? Styles.open : ''} ` }
                        value = { el.value }
                        onClick = {  () => handleChange(el) }>
                        {el.label}
                    </div>
                ) : null }
            </React.Fragment>
        );
    });

    return (
        <div className = { StylesForm.field }>
            { labelTxt && (
                <div className = { StylesForm.label }>{labelTxt}</div>
            ) }
            <div className = { Styles.wrap }>
                <div
                    className = { `${Styles.input} ${isOpen ? Styles.open : ''} ` }
                    onClick = { ()=>setIsOpen(!isOpen) }>
                    { `${_placeholder}` }
                    <div
                        className = { Styles.icon }>
                        <img
                            alt = ''
                            src = { '/icons/Down.svg' }
                        />
                    </div>
                </div>
                <div className = { `${Styles.listWrap} ${isOpen ? Styles.open : ''} ` }>
                    <div
                        className = { `${Styles.item} ${isOpen ? Styles.open : ''} ` }
                        value = { '' }
                        onClick = {  () => handleChange('') }>
                        {label || placeholder}
                    </div>
                    {optionJSX}
                </div>
            </div>
        </div>
    );
};
