/* eslint-disable max-params */
import React, { useEffect, useRef } from 'react';

const PieChart = ({
    data,
    wins,
    losses,
    thickness = 30,
}) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) {
            return;
        }

        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return;
        }

        const drawDonutSlice = (
            ctx,
            centerX,
            centerY,
            outerRadius,
            innerRadius,
            startAngle,
            endAngle,
            color,
        ) => {
            // Малюємо сектор
            ctx.fillStyle = color;
            ctx.beginPath();
            ctx.moveTo(centerX, centerY); // Точка в центрі для чіткого з'єднання
            ctx.arc(centerX, centerY, outerRadius, startAngle, endAngle - 0.01); // Проміжок для уникнення перекриттів
            ctx.arc(centerX, centerY, innerRadius, endAngle - 0.01, startAngle, true);
            ctx.closePath();
            ctx.fill();

            // Малюємо білий бордер
            ctx.strokeStyle = '#ffffff';
            ctx.lineWidth = 2; // Збільшення товщини для уникнення артефактів
            ctx.beginPath();
            ctx.arc(centerX, centerY, outerRadius, startAngle, endAngle);
            ctx.arc(centerX, centerY, innerRadius, endAngle, startAngle, true);
            ctx.closePath();
            ctx.stroke();
        };
        const drawDonutChart = (data) => {
            const total = data.reduce((sum, item) => sum + (item.value === 0 ? 1 : item.value), 0); // Використовуємо 1 для нульових значень
            let startAngle = 0;

            data.forEach((item) => {
                const sliceAngle = (item.value === 0 ? 1 : item.value) / total * 2 * Math.PI; // Пропорція для кута
                const endAngle = startAngle + sliceAngle;

                const outerRadius = Math.min(canvas.width / 2, canvas.height / 2) - 10;
                const innerRadius = outerRadius - thickness;

                drawDonutSlice(
                    ctx,
                    canvas.width / 2,
                    canvas.height / 2,
                    outerRadius,
                    innerRadius,
                    startAngle,
                    endAngle,
                    item.color,
                );

                startAngle = endAngle;
            });
        };


        ctx.clearRect(0, 0, canvas.width, canvas.height);

        drawDonutChart(data);
    }, [ wins, losses, thickness, data ]);

    return (
        <canvas
            height = { 240 }
            ref = { canvasRef }
            width = { 240 }
        />
    );
};

export default PieChart;
