import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../../instruments';
import AccountserviceCompany from '../serviceCompany';

export const serviceCompanyRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <AccountserviceCompany
                        { ...props }
                    />
                }
                path = { book.admin.serviceCompany }
            />
        </Routes>
    );
};
