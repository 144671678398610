const IconS = { fontSize: 18, color: '#red', marginRight: '5px' };

const IconButtonS = { padding: '2px 5px' };
const thStyle = { display: 'inline-block', width: '100%', position: 'reletive', textAlign: 'center' };
const thTextStyle = { display: 'block', lineHeight: '1.2', fontSize: '12px', fontWeight: '600' };
const styleFilter = { display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "10px 15px",  padding: "10px 15px", boxShadow: "0px 0px 15px -10px", borderRadius: "10px" };

const stylePAgination = {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    margin:         '15px 0',
};
const tabsStyles = {
    background:     '#fff',
    borderRadius:   '10px',
    width:          '98%',
    margin:         '20px auto',
    boxShadow:      '0px 0px 15px -10px',
    justifyContent: 'space-between',
};
const tableStyles = {
    marginTop: '20px',
    minWidth:  650,
};

const tabStyles = {
    padding:   '10px',
    color:     '#424242',
    fontSize:  "13px",
    '&:hover': {
        color:        '#1976d2',
        borderBottom: 'solid 2px #1976d2',
    },
};
const btnStyle = { display: 'block', width: "100%", marginBottom: '5px', padding: '3px 2px 1px' };

export  {
    IconS,
    IconButtonS,
    stylePAgination,
    thTextStyle,
    thStyle,
    styleFilter,
    tabsStyles,
    tabStyles,
    btnStyle,
    tableStyles,
};
