import React from 'react';

export const Txt = (props) => {
    const { fieldKey, item } = props;

    // Функція для парсингу стрічки
    const parseValue = (value) => {
        // Перевіряємо, чи це валідна стрічка
        if (typeof value !== 'string') {
            return null;
        }

        // Розбиваємо на основні частини
        const match = value.match(/([\d.]+) \(([\d.]+) \/ ([\\-\d.]+)%\)/);

        if (match) {
            const total = parseFloat(match[ 1 ]);
            const previous = parseFloat(match[ 2 ]);
            const dynamic = parseFloat(match[ 3 ]);

            return { total, previous, dynamic };
        }

        return null;
    };

    const parsed = parseValue(item[ fieldKey ]);

    if (!parsed) {
        return <span key = { fieldKey }>{item[ fieldKey ]}</span>; // Якщо парсинг не вдався, повертаємо початкове значення
    }

    return (
        <span key = { fieldKey }>
            ${parsed.total}
            <span
                style = {{
                    color: parsed.dynamic < 0 ? 'red' : 'green',
                }}>
                {' ('}${parsed.previous}
            </span>{' / '}
            <span
                style = {{
                    color: parsed.dynamic < 0 ? 'red' : 'green',
                }}>
                {parsed.dynamic} %{')'}
            </span>
        </span>
    );
};
