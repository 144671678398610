// Instruments
import { types } from './types';

const initialState = {
    isLogged:   false,
    isFetching: false,
    userData:   {},
    company:    {},
    lang:       'en',
    translate:  {},

};

export const apiReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER_DATA: {
            return {
                ...state,
                userData: action.payload,
            };
        }
        case types.SET_IS_FETCHING: {
            return {
                ...state,
                isFetching: action.payload,
            };
        }
        case types.SET_IS_LOGGED: {
            return {
                ...state,
                isLogged: action.payload,
            };
        }
        case types.SET_COMPANY: {
            return {
                ...state,
                company: action.payload,
            };
        }
        case types.SET_LANG: {
            return {
                ...state,
                lang: action.payload,
            };
        }
        case types.SET_TRANSLATE: {
            return {
                ...state,
                translate: action.payload,
            };
        }
        default:
            return state;
    }
};
