import React, { useState } from 'react';
import Styles from './styles.module.scss';
import { useSelector } from 'react-redux';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const [ inputValue, setInputValue ] = useState('');
    const [ timeoutId, setTimeoutId ] = useState(null);
    const state = useSelector(selectState);
    const translate = state.translate;
    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const onChange = (event) => {
        const page = event.target.value;
        setInputValue(page);

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = setTimeout(() => {
            const parsedPage = parseInt(page, 10);
            if (parsedPage > 0 && parsedPage <= totalPages) {
                onPageChange(parsedPage);
            }
        }, 500); // Затримка 500 мс

        setTimeoutId(newTimeoutId);
    };

    return (
        <div className = { Styles.wrap }>
            <div className = { Styles.select }>
                { `${translate.pg1}:` }
                <input
                    className = { Styles.input }
                    type = 'text'
                    value = { inputValue }
                    onChange = { onChange }
                />
            </div>
            <div className = { Styles.pagination }>
                <div
                    className = { Styles.item }
                    onClick = { () => handlePageChange(currentPage - 1) }>
                    <svg
                        fill = 'none'
                        height = '8'
                        viewBox = '0 0 4 8'
                        width = '4'
                        xmlns = 'http://www.w3.org/2000/svg'>
                        <path
                            clipRule = 'evenodd'
                            d = 'M3.85355 0.646447C3.65829 0.451184 3.34171 0.451184 3.14645 0.646447L0.146447 3.64645C-0.0488157 3.84171 -0.0488157 4.15829 0.146447 4.35355L3.14645 7.35355C3.34171 7.54882 3.65829 7.54882 3.85355 7.35355C4.04882 7.15829 4.04882 6.84171 3.85355 6.64645L1.20711 4L3.85355 1.35355C4.04882 1.15829 4.04882 0.841709 3.85355 0.646447Z'
                            fill = '#272727'
                            fillRule = 'evenodd'
                        />
                    </svg>

                </div>
                { currentPage > 2 && (
                    <div
                        className = { `${Styles.item}` }
                        onClick = { () => handlePageChange(1) }>
                        1
                    </div>
                )}
                { currentPage > 2 && (
                    <span className = { `${Styles.dot}` }>...</span>
                )}
                { currentPage - 1 > 0 && (
                    <div
                        className = { `${Styles.item}` }
                        onClick = { () => handlePageChange(currentPage - 1) }>
                        { currentPage - 1 }
                    </div>
                ) }
                <span className = { `${Styles.item} ${Styles.active}` }>{currentPage}</span>
                {currentPage + 1 <= totalPages && (
                    <div
                        className = { `${Styles.item}` }
                        onClick = { () => handlePageChange(currentPage + 1) }>
                        {currentPage + 1}
                    </div>
                )}
                { currentPage < totalPages - 1 && (
                    <span className = { `${Styles.dot}` }>...</span>
                )}
                { currentPage + 1 < totalPages && (
                    <div
                        className = { `${Styles.item}` }
                        onClick = { () => handlePageChange(totalPages) }>
                        { totalPages }
                    </div>
                )}
                <div
                    className = { `${Styles.item}` }
                    onClick = { () => handlePageChange(currentPage + 1) }>
                    <svg
                        fill = 'none'
                        height = '8'
                        viewBox = '0 0 4 8'
                        width = '4'
                        xmlns = 'http://www.w3.org/2000/svg'>
                        <path
                            clipRule = 'evenodd'
                            d = 'M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L3.85355 3.64645C4.04882 3.84171 4.04882 4.15829 3.85355 4.35355L0.853553 7.35355C0.658291 7.54882 0.341709 7.54882 0.146447 7.35355C-0.0488155 7.15829 -0.0488155 6.84171 0.146447 6.64645L2.79289 4L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z'
                            fill = '#272727'
                            fillRule = 'evenodd'
                        />
                    </svg>

                </div>
            </div>
        </div>

    );
};

export default Pagination;
