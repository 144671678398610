import React, { useState, useEffect } from 'react';
import Styles from './styles/select.module.scss';
import StylesForm from '../../../theme/styles/form.module.scss';

export const InputCheckbox = (props) => {
    const {
        valueField,
        nameField,
        setFieldValue,
        index = '',
        edit,
        placeholder = '',
        clear,
        isFilter,
        labelTxt,
        label = '',
    } = props;
    const [ isOpen, setIsOpen ] = useState(false);
    const [ _placeholder, setPlaceholder ] = useState(label || placeholder);

    const listOpt = [
        {
            value: true,
            label: 'Так',
        },
        {
            value: false,
            label: 'Ні',
        },
    ];

    const handleChange = (el) => {
        setFieldValue(nameField, el.value, index);
        if (typeof el.value === 'boolean') {
            setPlaceholder(el.label);
        } else {
            setPlaceholder(label || placeholder);
        }
        setIsOpen(false);
    };

    useEffect(() => {
        if (clear) {
            setPlaceholder(label || placeholder);
        }
    }, [ clear ]);

    useEffect(() => {
        if (edit) {
            const item = listOpt ? listOpt.find((el)=>el.value === valueField) : null;
            if (item) {
                setPlaceholder(item.label);
            }
        }
    }, [ valueField, edit ]);

    const optionJSX = edit && listOpt.map((el) => {
        let show = true;
        if (el.isFilter) {
            show =  !!isFilter;
        }

        return (
            <React.Fragment key = { el.value }>
                { el.label && show ? (
                    <div
                        className = { `${Styles.item} ${isOpen ? Styles.open : ''} ` }
                        value = { el.value }
                        onClick = {  () => handleChange(el) }>
                        {el.label}
                    </div>
                ) : null }
            </React.Fragment>
        );
    });

    return (
        <>
            { edit ? (
                <div className = { StylesForm.field }>
                    { labelTxt && (
                        <div className = { StylesForm.label }>{labelTxt}</div>
                    ) }
                    <div className = { Styles.wrap }>
                        <div
                            className = { `${Styles.input} ${isOpen ? Styles.open : ''} ` }
                            onClick = { ()=>setIsOpen(!isOpen) }>
                            { `${_placeholder}` }
                            <div
                                className = { Styles.icon }>
                                <img
                                    alt = ''
                                    src = { '/icons/Down.svg' }
                                />
                            </div>
                        </div>
                        <div className = { `${Styles.listWrap} ${isOpen ? Styles.open : ''} ` }>
                            <div
                                className = { `${Styles.item} ${isOpen ? Styles.open : ''} ` }
                                onClick = {  () => handleChange('') }>
                                {label || placeholder}
                            </div>
                            {optionJSX}
                        </div>
                    </div>
                </div>
            ) : (
                <>{ valueField ? (
                    <svg
                        fill = 'none'
                        height = '20'
                        viewBox = '0 0 20 20'
                        width = '20'
                        xmlns = 'http://www.w3.org/2000/svg'>
                        <rect
                            fill = '#272727'
                            height = '20'
                            rx = '4'
                            width = '20'
                        />
                        <path
                            d = 'M15.3334 6L8.00008 13.3333L4.66675 10'
                            stroke = 'white'
                            strokeLinecap = 'round'
                            strokeLinejoin = 'round'
                            strokeWidth = '1.25'
                        />
                    </svg>
                ) : (
                    <svg
                        fill = 'none'
                        height = '20'
                        viewBox = '0 0 20 20'
                        width = '20'
                        xmlns = 'http://www.w3.org/2000/svg'>
                        <rect
                            height = '19'
                            rx = '3.5'
                            stroke = '#E8E8E8'
                            width = '19'
                            x = '0.5'
                            y = '0.5'
                        />
                    </svg>
                ) }
                </>
            ) }
        </>
    );
};
