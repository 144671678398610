export const en = {
    code:  'en',
    title: 'English',
    l1:    'Welcome back!',
    l2:    'Enter your email and password to log in to your account.',
    l3:    'Email',
    l4:    'Password',
    l5:    'User not found',
    l6:    'Wrong password',
    l7:    'Forgot password?',
    l8:    'Login',
    cl1:   'Your time',
    cl2:   'Work time',
    hb1:   'Additional balance',
    hb2:   'My balance',
    so:    'Sign out',
    mn1:   'Clients',
    mn2:   'Companies',
    mn3:   'Numbers',
    mn4:   'Call Statistics',
    mn5:   'Services',
    mn6:   'Providers',
    mn7:   'Analytics',
    mn8:   'Service Balance',
    mn9:   'Settings',
    sb1:   'Hide panel',
    sb2:   'Open panel',
    ad1:   'Add User',
    ad2:   'Add Company',
    ad3:   'Add Number',
    ad4:   'Add Provider',
    ad5:   'Add',
    ed1:   'Edit User',
    ed2:   'Edit Company',
    ed3:   'Edit Number',
    ed4:   'Edit Provider',
    bt1:   'Go back',
    bt2:   'Save',
    bt3:   'Search',
    bt4:   'Filters',
    bt5:   'Clear filters',
    bt6:   'Merge',
    bt7:   'Connect company numbers',
    bt8:   'Load supplier numbers',
    bt9:   'Disconnect numbers',
    bt10:  'Upload',
    bt12:  'Additional services',
    pg1:   'Go to',
    tx1:   'There`s nothing here yet, but that won`t last long! 😉',
    tx2:   'All',
    tx3:   'last month',
    tx4:   'Total',
    tx5:   'Country',
    tx6:   'countries',
    tx7:   'Active',
    tx8:   'Inactive',
    t1:    'Call statistics',
    t2:    'Region statistics',
    t3:    'My numbers',
    t4:    'Дополнительные услуги',
    t5:    'List',
    t6:    'Analytics',
    st1:   '% of dials',
    st2:   'ADD',
    st3:   'Calls',
    st4:   'Costs',
    st5:   'Session information.',
    st6:   'Time on call',
    st7:   'Costs by geolocation',
    st8:   'Overall Analytics',
    st9:   'Diagram',
    st10:  'Dynamics',
    f1:    'Username',
    f2:    'Telegram ID',
    f3:    'Role',
    f4:    'Companies',
    f5:    'Representative Percentage',
    f6:    'Partner Percentage',
    f7:    'Password',
    f8:    'Email',
    f9:    'Action',
    f10:   'Company',
    f11:   'MOR',
    f12:   'ATS',
    f13:   'Manager',
    f14:   'Credit limit',
    f15:   'MOR List',
    f16:   'Payment date',
    f17:   'Service balance',
    f18:   'Partner',
    f19:   'Country',
    f20:   'Phone',
    f21:   'Type',
    f22:   'Supplier',
    f23:   'Price Supplier',
    f24:   'Subscription Supplier',
    f25:   'Price for company',
    f26:   'Subscription for company',
    f27:   'Deletion from supplier',
    f28:   'Reserve',
    f29:   'Date created',
    f30:   'Date',
    f31:   'Number',
    f32:   'Call to',
    f33:   'Duration',
    f34:   'Status',
    f35:   'Reason for hanging',
    f36:   'Server',
    f37:   'Profit',
    f38:   'Date of connection',
    f39:   'Date of writing off',
    f40:   'Cost of connection',
    f41:   'Subscription fee for the number',
    f42:   'Name',
    f43:   'Subscription fee',
    f44:   'Balance',
    f45:   'Subscription charge when conne cting',
    f46:   'Representative',
    f47:   'Representative percentage',
    f49:   'Comment',
    f50:   'Amount',
    f51:   'Service',
    f52:   'Transaction amount',
    f53:   'Purchase amount',
    f54:   'Manager bonus',
    f55:   'Sur bonus',
    f56:   'Partner bonus',
    f57:   'Written off / topped up',
    f58:   'Leftover',
    f59:   'Description',
    f60:   'Is deleded',
    f61:   'Spam Checker',
    f62:   'SMS',
    f63:   'Total Profit',
    f65:   'Profit by Calls',
    f66:   'Profit by Numbers',
    f67:   'Profit by PBX',
    f68:   'Profit by SMS',
    f69:   'Profit by Spam Checker',
    f70:   'Information',

};
