import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../../instruments';
import AccountserviceBalance from '../serviceBalance';

export const serviceBalanceRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <AccountserviceBalance
                        { ...props }
                    />
                }
                path = { book.admin.serviceBalance }
            />
        </Routes>
    );
};
