// eslint-disable-line
import React, { useEffect, useState } from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import { InputMask } from './inputMask';
import { useSelector } from 'react-redux';
import { setTraslate } from '../../../instruments/setTraslate';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

function Phone(props) {
    const state = useSelector(selectState);
    const translate = state.translate;
    const [ list, setList ] = useState({ ...listing.list });

    useEffect(()=>{
        const _list = setTraslate({ ...listing.list }, translate);
        setList(_list);
    }, [ translate, listing.list ]);

    const CustomComponents = {
        phone: InputMask,
    };

    const btns = [
        {
            component: 'ImportCsv',
            apiUrl:    `/import_phone`,
            name:      translate.bt7,
            type:      'phone',
        },
        {
            component: 'ImportCsv',
            apiUrl:    `/import_phone_postman`,
            name:      translate.bt8,
            type:      'phone',
        },
        {
            component: 'ImportCsv',
            apiUrl:    `/import_disconect_phone`,
            name:      translate.bt9,
            type:      'import_disconect_phone',
        },
    ];

    return (
        <ListConstructor
            { ...props }
            isShowAddBtn
            CustomComponents = { CustomComponents }
            btns = { btns }
            fields = { list }
            limit = { 10 }
            moduleTitle = { translate.mn3 }
            sortDefault = 'createdAt'
            sortOrderDefault = { false }
            type = { 'phone' }
            urlApi = { '/phone' }
        />
    );
}

export default Phone;
