const listing = {
    amount: {
        type:  'text',
        title: 'f57',
        width: '10',
        sort:  true,
    },
    remainder: {
        type:  'text',
        title: 'f58',
        width: '10',
        sort:  true,
    },
    date: {
        type:        'date',
        title:       'f30',
        width:       '10',
        sort:        true,
        filter:      'DateRangeField',
        sort_filter: 3,
    },
    comment: {
        type:  'text',
        title: 'f59',
        width: '10',

    },

};
export { listing };
