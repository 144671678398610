import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Styles from './balance.module.scss';

const selectState = (state) => ({
    company:   state.swapi.company,
    translate: state.swapi.translate,
});

function Balance() {
    const [ balance, setBalance ] = useState(0);
    const [ balancePhone, setBalancePhone ] = useState(0);
    const state = useSelector(selectState);
    const company = state.company;
    const translate = state.translate;

    useEffect(() => {
        if (company?._id) {
            setBalance(company.balance || 0);
            setBalancePhone(company.balance_phone || 0);
        }
    }, [ company ]);

    return (
        <div className = { Styles.wrap }>
            <div className = { Styles.item }>{ `${translate.hb1}: ` }<span>{ `$${balancePhone}` }</span></div>
            <div className = { `${Styles.item} ${Styles.dark} ` }>{ `${translate.hb2}:` }<span>{ `$${balance}` }</span></div>
        </div>
    );
}
export default Balance;
