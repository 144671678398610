import React from 'react';

function ServiceCompanyHistory(props) {
    return (
        <svg
            className = { props.className }
            fill = 'none'
            height = '18'
            viewBox = '0 0 14 18'
            width = '14'
            xmlns = 'http://www.w3.org/2000/svg'>
            <path
                clipRule = 'evenodd'
                d = 'M7.00004 2.48965L11.5844 2.50081C11.8141 2.50137 12 2.68659 12 2.91488V15.403C12 15.7927 11.5077 15.9666 11.2602 15.6643L9.94861 14.0625C9.61693 13.6574 8.99535 13.6548 8.66027 14.0571L7.29711 15.6937C7.13868 15.8839 6.84901 15.8949 6.67641 15.7173L5.01131 14.0038C4.64684 13.6287 4.02847 13.6777 3.72834 14.1053L2.75846 15.4873C2.52521 15.8196 2.00004 15.6556 2.00004 15.2504V2.90371C2.00004 2.67503 2.18659 2.48965 2.41671 2.48965H7.00004ZM7.00413 0.833379L11.5885 0.844545C12.7371 0.847343 13.6667 1.77344 13.6667 2.91488V15.403C13.6667 17.3515 11.2049 18.221 9.9674 16.7097L9.2967 15.8905L8.58101 16.7498C7.78886 17.7009 6.3405 17.756 5.4775 16.8679L4.51598 15.8784L4.1255 16.4348C2.95923 18.0966 0.333374 17.2764 0.333374 15.2504V2.90371C0.333374 1.7603 1.26611 0.833374 2.41671 0.833374L7.00413 0.833379ZM3.66671 9.94287C3.66671 9.4855 4.0398 9.11473 4.50004 9.11473H9.50004C9.96028 9.11473 10.3334 9.4855 10.3334 9.94287C10.3334 10.4002 9.96028 10.771 9.50004 10.771H4.50004C4.0398 10.771 3.66671 10.4002 3.66671 9.94287ZM4.50004 4.97405C4.0398 4.97405 3.66671 5.34482 3.66671 5.80219C3.66671 6.25956 4.0398 6.63032 4.50004 6.63032H9.50004C9.96028 6.63032 10.3334 6.25956 10.3334 5.80219C10.3334 5.34482 9.96028 4.97405 9.50004 4.97405H4.50004Z'
                fillRule = 'evenodd'
            />
        </svg>
    );
}

export default ServiceCompanyHistory;
