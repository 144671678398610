
const listing = {};

listing.list = {
    country: {
        type:  'text',
        title: 'f19',
        width: '5',
    },
    phone: {
        type:        'copy',
        title:       'f20',
        width:       '15',
        filter:      'Input',
        component:   'Input',
        sort_filter: 1,
        isSearch:    true,

    },
    type: {
        type:        'text',
        title:       'f21',
        width:       '15',
        filter:      'Select',
        sort_filter: 2,
    },
    postman: {
        type:        'text',
        title:       'f22',
        width:       '15',
        component:   'SearchV2',
        filter:      'SearchV2',
        sort_filter: 3,
    },
    companyId: {
        type:        'text',
        title:       'f10',
        width:       '15',
        filter:      'SearchV2',
        typeSearch:  'user_id',
        sort_filter: 4,
    },
    price_postman: {
        type:      'text',
        title:     'f23',
        width:     '10',
        component: 'Input',

    },
    price_postman_subscription: {
        type:      'text',
        title:     'f24',
        width:     '10',
        component: 'Input',

    },

    price: {
        type:      'text',
        title:     'f25',
        width:     '10',
        component: 'Input',
    },
    price_subscription: {
        type:      'text',
        title:     'f26',
        width:     '10',
        component: 'Input',
    },

    is_delete_after_pay: {
        type:        'checkbox',
        title:       'f27',
        width:       '5',
        component:   'InputCheckbox',
        filter:      'InputCheckbox',
        sort_filter: 5,
    },
    is_deleted: {
        type:        'checkbox',
        title:       'f60',
        width:       '5',
        filter:      'InputCheckbox',
        sort_filter: 5,
        sort:        true,
    },
    is_reserved: {
        type:        'checkbox',
        title:       'f28',
        width:       '9',
        filter:      'InputCheckbox',
        component:   'InputCheckbox',
        sort_filter: 6,
    },
    createdAt: {
        sort:  true,
        type:  'date',
        title: 'f29',
        width: '5',
    },
    delete: {
        type:   'delete',
        title:  'f9',
        values: [ 'rowEdit', 'delete' ],
        width:  '5',
    },
};

export { listing };
