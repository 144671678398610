// eslint-disable-line
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiActions } from '../../../bus/actions';
import { Dialog } from '@mui/material';
import { useNavigate } from 'react-router';
import { removeSession, saveSession } from '../../../instruments';
import { api } from '../../../Api';
import StylesForm from '../../../theme/styles/form.module.scss';
import StylesFilter from '../../../theme/styles/filter.module.scss';

const selectState = (state) => ({
    isFetching: state.swapi.isFetching,
    isLogged:   state.swapi.isLogged,
    userData:   state.swapi.userData,
});

export const Agreement = () => {
    const state = useSelector(selectState);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLogged = state.isLogged;
    const userData = state.userData;
    const [ open, setOpen ] = useState(false);

    useEffect(() => {
        if (isLogged && userData && userData.role === 'client' && !userData.agreement) {
            setOpen(true);
        }
    }, [ isLogged, userData ]);

    const handleOk = async () => {
        const props = {
            token: userData.token,
        };
        const data = await api.postData(props, '/agreement');
        if (data && data.status) {
            const user = {
                ...userData,
                agreement: true,
            };
            dispatch(apiActions.setUserData(user));
            saveSession(user);
        }
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
        removeSession();
        dispatch(apiActions.setIsLogged(false));
        dispatch(apiActions.setUserData(null));
        dispatch(apiActions.setCompany({}));
        const url = '/login';
        navigate(url);
    };


    return (
        <Dialog
            PaperProps = {{
                sx: {
                    width:   '500px',
                    padding: '32px',
                    gap:     '24px',
                },
            }}
            open = { open }>
            <div className = { `${StylesForm.title}` }>{`Terms of Use`}</div>
            <div>
                <p>
                    {`By using this website, you agree to our `}
                    <a
                        href = '/'
                        target = '_blank'>
                        {`Terms of Use`}
                    </a>,
                    <a
                        href = '#'
                        target = '_blank'>{` Privacy Policy`}
                    </a>,
                    {` and `}
                    <a
                        href = '#'
                        target = '_blank' >{`Cookie Policy`}
                    </a>
                    {`. Please read these documents carefully.`}
                </p>
                <p>
                    {`If you do not agree with these terms, you must not use our website or services.`}
                </p>
            </div>
            <div className = { StylesFilter.dialogButtons }>
                <div
                    className = { StylesFilter.btnOutline }
                    onClick = { () => handleClose() }>
                    {`Cancel`}
                </div>
                <div
                    className = { StylesFilter.btn }
                    onClick = { () => handleOk() }>
                    {`I Agree`}
                </div>
            </div>
        </Dialog>
    );
};
