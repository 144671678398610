const listing = {};

listing.list = {
    name: {
        type:        'text',
        title:       'f1',
        width:       '8',
        filter:      'Input',
        component:   'Input',
        sort_filter: 1,
        isSearch:    true,

    },
    telegram_id: {
        type:      'text',
        title:     'f2',
        width:     '8',
        // filter:    'Input',
        component: 'Input',
    },
    role: {
        type:      'text',
        title:     'f3',
        width:     '5',
        filter:    'Select',
        component: 'Select',
        //   role_edit: [ 'admin' ],
    },
    company_id: {
        type:      'arrSelect',
        title:     'f4',
        width:     '15',
        filter:    'SearchV2',
        component: 'MultiSearch',
    },
    survisorPercent: {
        type:      'text',
        title:     'f5',
        width:     '5',
        component: 'Input',
        roles:     [ 'admin' ],

    },

    password: {
        type:      'text',
        title:     'f7',
        width:     '5',
        // filter:    'Input',
        component: 'Input',
        onlyNew:   true,
    },
    email: {
        type:      'text',
        title:     'f8',
        width:     '7',
        // filter:    'Input',
        component: 'Input',
    },
    delete: {
        type:   'delete',
        title:  'f9',
        values: [ 'rowEdit', 'delete' ],
        width:  '1',
    },
};


listing.sendpulse_status = [
    { label: 'не перевірений', value: 0 },
    { label: 'дійсний', value: 1 },
    { label: 'непідтвердженний', value: 2 },
    { label: 'недійсний', value: 3 },
];

export { listing };
