import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../../instruments';
import AccountCopyModule from '../Profit';

export const ProfitRouter = (props) => {
    const params = window.location.pathname;
    const type = params.split('/').pop();

    return (
        <Routes>
            <Route
                exact
                element = {
                    <AccountCopyModule
                        { ...props }
                        type = { type }
                    />
                }
                path = { book.admin.profit }
            />
        </Routes>
    );
};
