import React, { useEffect, useState } from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Styles from '../../../theme/styles/pageHeader.module.scss';
import { setTraslate } from '../../../instruments/setTraslate';

const selectState = (state) => ({
    userData:  state.swapi.userData,
    translate: state.swapi.translate,
});


function AccountAnalytics(props) {
    const state = useSelector(selectState);
    const translate = state.translate;
    const  userData  = state.userData;
    const [ list, setList ] = useState({ ...listing.list });

    useEffect(()=>{
        const _list = setTraslate({ ...listing.list }, translate);
        setList(_list);
    }, [ translate, listing.list ]);

    const filter = {

        from: moment().date(1)
            .hour(0)
            .minute(0)
            .second(0),
    };

    if (userData.role !== 'admin') {
        filter.managerId = {
            value: userData._id,
            label: userData.name,
        };
    }

    return (
        <>
            <div className = { Styles.header }>
                <div className = { Styles.title }>
                    { translate.mn7 }
                </div>
            </div>
            <ListConstructor
                { ...props }
                isDefaultFilter
                fields = { list }
                filterOutside = { filter }
                limit = { 10 }
                sortDefault = 'createdAt'
                sortOrderDefault = { false }
                type = { 'analytics' }
                urlApi = { '/analytics' }
            />
        </>
    );
}

export default AccountAnalytics;
