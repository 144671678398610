import React, { useEffect, useState } from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import Import from '../Import';
import Styles from '../../../theme/styles/pageHeader.module.scss';
import { useSelector } from 'react-redux';

import Charts from './charts';
import { api } from '../../../Api';
import { setTraslate } from '../../../instruments/setTraslate';

const selectState = (state) => ({
    company:   state.swapi.company,
    userData:  state.swapi.userData,
    translate: state.swapi.translate,
});

function AccountCalls(props) {
    const now = new Date();
    const start = new Date(now.setUTCDate(1)).toISOString();

    const state = useSelector(selectState);
    const translate = state.translate;
    const  userData  = state.userData;
    const company = state.company;
    const [ list, setList ] = useState({ ...listing.list });
    const [ filter, setFilter ] = useState({ calldate: { from: start } });

    useEffect(()=>{
        const _list = setTraslate({ ...listing.list }, translate);
        setList(_list);
    }, [ translate, listing.list ]);

    const exportFile = async() => {
        await api.postDataDownload({ ...filter }, '/exportCalls');
    };

    const setCallBack = (value) => {
        setFilter(value);
    };

    return (
        <>
            <div className = { Styles.header }>
                <div className = { Styles.title }>
                    { translate.mn4 }
                </div>
                <div className = { Styles.filter }>
                    <div
                        className = { Styles.download }
                        onClick = { ()=>exportFile() }>
                        <img
                            alt = ''
                            src = { '/icons/download.svg' }
                        />
                        { translate.bt10 }
                    </div>
                    { userData?.role === 'admin' && (
                        <Import
                            { ...props }
                            apiUrl =  { `/import_mor` }
                            name = 'Import mor'
                            type = { 'phone' }
                        />
                    ) }
                </div>
            </div>
            <Charts
                company_id = { company._id }
                filter = { filter }
            />
            <ListConstructor
                { ...props }
                isDefaultFilter
                fields = { list }
                filterOutside = { filter }
                limit = { 10 }
                setCallBack = { setCallBack }
                sortDefault = 'calldate'
                sortOrderDefault = { false }
                type = { 'calls' }
                urlApi = { '/calls' }
            />
        </>
    );
}

export default AccountCalls;
