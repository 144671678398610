import React from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import Styles from '../../../theme/styles/pageHeader.module.scss';

function AccountService(props) {
    return (
        <>
            <div className = { Styles.header }>
                <div className = { Styles.title }>
                    Послуги
                </div>
            </div>
            <ListConstructor
                { ...props }
                isShowAddBtn
                fields = { listing.list }
                limit = { 10 }
                sortDefault = 'name'
                sortOrderDefault = { false }
                type = { 'service' }
                urlApi = { '/service' }
            />
        </>
    );
}

export default AccountService;
