import React from 'react';

function Company(props) {
    return (
        <svg
            className = { props.className }
            fill = 'none'
            height = '16'
            viewBox = '0 0 18 16'
            width = '18'
            xmlns = 'http://www.w3.org/2000/svg'>
            <path
                clipRule = 'evenodd'
                d = 'M2.33341 0.5C1.41294 0.5 0.666748 1.24619 0.666748 2.16667V11.3333C0.666748 12.2538 1.41294 13 2.33341 13H6.50008L5.16675 14C4.79856 14.2761 4.72394 14.7985 5.00008 15.1667C5.27622 15.5349 5.79856 15.6095 6.16675 15.3333L9.00008 13.2083L11.8334 15.3333C12.2016 15.6095 12.7239 15.5349 13.0001 15.1667C13.2762 14.7985 13.2016 14.2761 12.8334 14L11.5001 13H15.6667C16.5872 13 17.3334 12.2538 17.3334 11.3333V2.16667C17.3334 1.24619 16.5872 0.5 15.6667 0.5H2.33341ZM8.99166 11.3333H9.00978H14.0001H15.6667V9.66667V3.83333V2.16667H14.0001H4.00008H2.33341V3.83333V9.66667V11.3333H4.00008H8.99038H8.99166ZM12.6333 7.39018C12.9868 7.68482 13.5123 7.63705 13.8069 7.28349C14.1016 6.92992 14.0538 6.40445 13.7002 6.10982L11.2002 4.02648C11.0047 3.8635 10.7453 3.79886 10.4961 3.85099C10.2469 3.90311 10.0352 4.06628 9.92139 4.29399L8.3086 7.51957L6.6169 6.10982C6.42993 5.95401 6.18419 5.88772 5.94422 5.92837C5.70426 5.96903 5.49406 6.11255 5.36884 6.32125L4.11884 8.40459C3.88205 8.79924 4.01002 9.31112 4.40467 9.54791C4.79932 9.7847 5.3112 9.65673 5.54799 9.26208L6.2974 8.01307L8.04993 9.47352C8.2455 9.6365 8.50485 9.70114 8.75404 9.64901C9.00323 9.59689 9.21492 9.43372 9.32877 9.20601L10.9416 5.98043L12.6333 7.39018Z'
                fillRule = 'evenodd'
            />
        </svg>

    );
}

export default Company;
