import React from 'react';
import Styles from '../../../theme/styles/pageHeader.module.scss';
import { Link } from 'react-router-dom';

function Tabs(props) {
    const { tabs } = props;

    return tabs?.length > 0 && (
        <div className = { Styles.tabs }>
            { tabs.map((item, index)=>{
                return (
                    <Link
                        className = { `${Styles.tab} ${item.active ? Styles.active : ''}` }
                        key = { index }
                        to = { item.to }>
                        { item.name }
                    </Link>
                );
            })}
        </div>
    );
}

export default Tabs;
