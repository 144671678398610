/* eslint-disable no-extra-parens */
import React, { useState, useEffect } from 'react';
import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker';
import Styles from './styles/DatePicker.module.scss';
import './styles/react-datepicker.css';
import en from 'date-fns/locale/en-GB';
import moment from 'moment';
registerLocale('en', en);

export const DateRangeField = (props) => {
    const { valueField, nameField, setFieldValue, showStartDate, disablePrevDates, beforeAllow, label = '', isTime, clear } = props;

    function getDateTime(now, interval) {
        return new Date(Math.round(new Date(now).getTime()
        / (interval * 1000)) * (interval * 1000));
    }

    let start = showStartDate ? getDateTime(new Date(), 1800) : '';
    start = valueField && valueField !== '' ? new Date(valueField) : start;
    const [ startDate, setStartDate ] = useState(start);
    const [ endDate, setEndDate ] = useState(new Date());

    useEffect(() => {
        if (clear) {
            const now = new Date();
            const oneMounth = new Date(now.getFullYear(), now.getMonth(), 1); // 1 число поточного місяця
            setStartDate(oneMounth);

            setEndDate(new Date());
        }
    }, [ clear ]);


    const onChange = (dates) => {
        const [ start, end ] = dates;
        if (start && end) {
            setFieldValue(nameField, dates);
        } else  if (start) {
            setFieldValue(nameField, start.toString());
        } else {
            setFieldValue(nameField, '');
        }
        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {
        if (valueField) {
            setStartDate(new Date(valueField));
        } else {
            setStartDate('');
        }
    }, [ valueField ]);

    // Визначення мінімальної та максимальної дати для вибору
    let minDate = null;

    if (disablePrevDates) {
        minDate = new Date();
    } else if (beforeAllow) {
        minDate = new Date(Date.now() - (beforeAllow * 24 * 60 * 60 * 1000));
    }

    const MyContainer = ({ className, children }) => {
        return (
            <div className = { Styles.calendarWrap }>
                <CalendarContainer className = { className }>
                    <div className = { Styles.dates }>
                        <div className = { Styles.item }>
                            { moment(startDate).format('MMM DD, yyyy') }
                        </div>
                        <div className = { Styles.item }>
                            { endDate && moment(endDate).format('MMM DD, yyyy') }
                        </div>
                    </div>
                    <div style = {{ position: "relative" }}>{children}</div>
                </CalendarContainer>
            </div>
        );
    };

    return (
        <div className = { Styles.wrap }>
            <DatePicker
                selectsRange
                calendarContainer = { MyContainer }
                dateFormat = { props.format ? props.format : 'MMM d, yyyy' }
                endDate = { endDate }
                locale = 'en'
                minDate = { minDate }
                placeholderText = { label }
                popperPlacement = 'bottom-end'
                showMonthYearPicker = { !!props.showMonthYearPicker }
                showTimeSelect = { isTime }
                startDate = { startDate }
                onChange = { (date) => onChange(date) }
            />
        </div>
    );
};
