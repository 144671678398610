import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Styles from './time.module.scss';
import { useSelector } from 'react-redux';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

function Clock() {
    const state = useSelector(selectState);
    const translate = state.translate;
    const [ value, setValue ] = useState('');
    const [ valueUTC, setValueUTC ] = useState('');
    const format = 'DD/MM/YY HH:mm:ss';

    useEffect(() => {
        const setData = () => {
            setValue(moment().format(format));
            setValueUTC(moment().utc()
                .add(1, 'hour')
                .format(format));
        };

        const interval = setInterval(() => {
            setData();
        }, 1000);

        setData();

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className = { Styles.wrap }>
            <div className = { Styles.time }>{ `${translate.cl1}: ` }<span>{value}</span></div>
            { valueUTC && (
                <div className = { Styles.time }>{ `${translate.cl2}: ` }<span>{valueUTC}</span>{ `(UTC+1)` }</div>
            ) }
        </div>
    );
}
export default Clock;
