import Company from './Company';
import Calls from './Calls';
import Setting from './Setting';
import ServiceCompanyHistory from './ServiceCompanyHistory';
import ServiceCompany from './ServiceCompany';
import Users from './Users';
import Phone from './Phone';
import Postman from './Postman';
import Analytics from './Analytics';
import ServiceBalance from './ServiceBalance';
import Profit from './Profit';

const Icons = {
    company:               Company,
    home:                  Company,
    calls:                 Calls,
    serviceCompany:        ServiceCompany,
    serviceBalance:        ServiceBalance,
    setting:               Setting,
    serviceCompanyHistory: ServiceCompanyHistory,
    users:                 Users,
    phone:                 Phone,
    postman:               Postman,
    analytics:             Analytics,
    'profit/1':            Profit,
    service:               ServiceCompany,
    // copyModule:            copyModule,

};

export default Icons;
