import React from 'react';

function Setting(props) {
    return (
        <svg
            className = { props.className }
            fill = 'none'
            height = '20'
            viewBox = '0 0 20 20'
            width = '20'
            xmlns = 'http://www.w3.org/2000/svg'>
            <path
                clipRule = 'evenodd'
                d = 'M7.91685 1.66675C7.54479 1.66675 7.2178 1.9134 7.11558 2.27115L6.48191 4.48901L4.2847 4.17512C3.91169 4.12184 3.54933 4.32548 3.4009 4.67182L2.1509 7.58848C2.01667 7.90168 2.08665 8.26505 2.3276 8.506L3.82168 10.0001L2.3276 11.4942C2.06187 11.7599 2.00687 12.1706 2.19332 12.4969L3.85998 15.4135C4.03305 15.7164 4.37644 15.8794 4.72052 15.8221L6.48664 15.5277L7.11558 17.729C7.2178 18.0868 7.54479 18.3334 7.91685 18.3334H12.0835C12.4556 18.3334 12.7826 18.0868 12.8848 17.729L13.5137 15.5277L15.2799 15.8221C15.6239 15.8794 15.9673 15.7164 16.1404 15.4135L17.8071 12.4969C17.9935 12.1706 17.9385 11.7599 17.6728 11.4942L16.1787 10.0001L17.6728 8.506C17.9137 8.26505 17.9837 7.90168 17.8495 7.58848L16.5995 4.67182C16.451 4.32548 16.0887 4.12184 15.7157 4.17512L13.5185 4.48901L12.8848 2.27115C12.7826 1.9134 12.4556 1.66675 12.0835 1.66675H7.91685ZM7.88479 5.64568L8.54544 3.33341H11.4549L12.1156 5.64568C12.23 6.04611 12.6224 6.3006 13.0347 6.24171L15.3191 5.91536L16.0953 7.72644L14.4109 9.41083C14.0855 9.73626 14.0855 10.2639 14.4109 10.5893L16.0442 12.2226L14.9813 14.0827L13.0539 13.7614C12.6358 13.6917 12.232 13.947 12.1156 14.3545L11.4549 16.6667H8.54544L7.88479 14.3545C7.76836 13.947 7.36459 13.6917 6.94652 13.7614L5.01907 14.0827L3.95618 12.2226L5.58944 10.5893C5.91488 10.2639 5.91488 9.73626 5.58944 9.41083L3.90506 7.72644L4.68123 5.91536L6.96567 6.24171C7.37793 6.3006 7.77038 6.04611 7.88479 5.64568ZM10.0002 11.6667C9.07971 11.6667 8.33352 10.9206 8.33352 10.0001C8.33352 9.07961 9.07971 8.33342 10.0002 8.33342C10.9207 8.33342 11.6669 9.07961 11.6669 10.0001C11.6669 10.9206 10.9207 11.6667 10.0002 11.6667ZM6.66685 10.0001C6.66685 8.15913 8.15924 6.66675 10.0002 6.66675C11.8411 6.66675 13.3335 8.15913 13.3335 10.0001C13.3335 11.841 11.8411 13.3334 10.0002 13.3334C8.15924 13.3334 6.66685 11.841 6.66685 10.0001Z'
                fillRule = 'evenodd'
            />
        </svg>
    );
}

export default Setting;
