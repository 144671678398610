export const ua = {
    code:  'ua',
    title: 'Українська',
    l1:    'Вітаємо з поверненням!',
    l2:    'Введіть електронну пошту та пароль, щоб увійти до свого облікового запису.',
    l3:    'Email',
    l4:    'Пароль',
    l5:    'Користувача не знайдено',
    l6:    'Некоректний пароль',
    l7:    'Забули пароль?',
    l8:    'Логін',
    cl1:   'Ваш час',
    cl2:   'Робочий час',
    hb1:   'Додатковий баланс',
    hb2:   'Мій баланс',
    so:    'Вийти',
    mn1:   'Користувачі',
    mn2:   'Компанії',
    mn3:   'Номери',
    mn4:   'Статистика дзвінків',
    mn5:   'Послуги',
    mn6:   'Постачальники',
    mn7:   'Аналітика',
    mn8:   'Баланс послуг',
    mn9:   'Налаштування',
    sb1:   'Сховати панель',
    sb2:   'Розкрити панель',
    ad1:   'Додати користувача',
    ad2:   'Додати компанію',
    ad3:   'Додати номер',
    ad4:   'Додати постачальника',
    ad5:   'Додати',
    ed1:   'Редагувати користувача',
    ed2:   'Редагувати компанію',
    ed3:   'Редагувати номер',
    ed4:   'Редагувати постачальника',
    bt1:   'Повернутись назад',
    bt2:   'Зберегти',
    bt3:   'Пошук',
    bt4:   'Фільтри',
    bt5:   'Скинути фільтри',
    bt6:   'Об’єднати',
    bt7:   'Підключити номера компаній',
    bt8:   'Завантажити номера постачальників',
    bt9:   'Відключити номера',
    bt10:  'Завантажити',
    bt12:  'Додаткові послуги',
    pg1:   'Перейти до',
    tx1:   'Поки що тут нічого, але це ненадовго! 😉',
    tx2:   'Усі',
    tx3:   'минулого місяця',
    tx4:   'Разом',
    tx5:   'Країна',
    tx6:   'країни',
    tx7:   'Активний',
    tx8:   'Неактивний',
    t1:    'Статистика дзвінків',
    t2:    'Статистика регіонів',
    t3:    'Мої номери',
    t4:    'Додаткові послуги',
    t5:    'Список',
    t6:    'Аналітика',
    st1:   '% Дозвонів',
    st2:   'AСД',
    st3:   'Дзвінки',
    st4:   'Витрати',
    st5:   'Інформація про сеанси.',
    st6:   'Час у дзвінку',
    st7:   'Витрати за геолокацією',
    st8:   'Загальна аналітика',
    st9:   'Діаграма',
    st10:  'Динаміка',
    f1:    'Імʼя користувача',
    f2:    'Телеграм ID',
    f3:    'Роль',
    f4:    'Компанії',
    f5:    'Процент представника',
    f6:    'Відсоток партнера',
    f7:    'Пароль',
    f8:    'Email',
    f9:    'Дія',
    f10:   'Компанія',
    f11:   'Мор',
    f12:   'АТС',
    f13:   'Менеджер',
    f14:   'Кредитний лімит',
    f15:   'Список Мор',
    f16:   'Дата платежу',
    f17:   'Баланс послуг',
    f18:   'Партнер',
    f19:   'Країна',
    f20:   'Телефон',
    f21:   'Тип',
    f22:   'Постачальник',
    f23:   'Ціна п-ка',
    f24:   'Абонплата п-ка',
    f25:   'Ціна для компанії',
    f26:   'Абонплата для компанії',
    f27:   'Видалення в постачальника',
    f28:   'Резерв',
    f29:   'Дата створення',
    f30:   'Дата',
    f31:   'Номер',
    f32:   'Дзвінок до',
    f33:   'Тривалість',
    f34:   'Статус',
    f35:   'Причина зависання',
    f36:   'Сервер',
    f37:   'Прибуток',
    f38:   'Дата підключення',
    f39:   'Дата списання',
    f40:   'Вартість підключення',
    f41:   'Абонплата за номер',
    f42:   'Назва',
    f43:   'Абонплата',
    f44:   'Баланс',
    f45:   'Списання абон. при підключенні',
    f46:   'Представник',
    f47:   'Процент представника',
    f49:   'Коментар',
    f50:   'Сума',
    f51:   'Послуга',
    f52:   'Сума транзакції',
    f53:   'Сума закупки',
    f54:   'Премія менеджера',
    f55:   'Премія сюр',
    f56:   'Премія партнера',
    f57:   'Списано / поповнено',
    f58:   'Залишок',
    f59:   'Опис',
    f60:   'На відключення',
    f61:   'Спам чекер',
    f62:   'СМС',
    f63:   'Загальний профіт',
    f65:   'Профіт по дзвінкам',
    f66:   'Профіт по номерам',
    f67:   'Профіт по АТС',
    f68:   'Профіт по смс',
    f69:   'Профіт по спам чекеру',
    f70:   'Інформація про сеанси',


};
