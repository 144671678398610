import React from 'react';
import Styles from './styles.module.scss';
import { useSelector } from 'react-redux';
const selectState = (state) => ({
    translate: state.swapi.translate,
});
export const Status = (props) => {
    const state = useSelector(selectState);
    const translate = state.translate;
    const {
        item = null,
        fieldKey = '',
    } = props;

    return item && (
        <div className = { `${Styles.status} ${item?.[ fieldKey ] ? Styles.active : ''}` }>
            <div  className = { `${Styles.dot}` }/>
            { item?.[ fieldKey ] ? translate.tx7 : translate.tx8 }
        </div>
    );
};
