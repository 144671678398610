import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../../../Api';
import ItemRender from './itemRender';
import striptags from 'striptags';
import StylesFilter from '../../../theme/styles/filter.module.scss';
import { titlesAdd, titlesEdit } from '../../../instruments/utils';

const selectState = (state) => ({
    userData:  state.swapi.userData,
    translate: state.swapi.translate,
});

function EditItem(props) {
    const { urlApi, fields, data, edit, setEdit, type, setIsAdd, isAdd, updateList,
        componentName } = props;
    const [ saveData, setSaveData ] = useState({});
    const [ isSave, setIsSave ] = useState(false);
    const [ item, setItem ] = useState(data);
    const [ hidden, setHidden ] = useState(false);
    const [ error, setError ] = useState('');
    const [ dataSearch, setDataSearch ] = useState({});
    const [ userData, setUserData ] = useState({});
    const state = useSelector(selectState);
    const  _userData  = state.userData;
    const translate = state.translate;

    useEffect(() => {
        setUserData(_userData);
    }, [  ]);

    useEffect(() => {
        if (error) {
            const timer = setTimeout(function () {
                setError('');
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [ error ]);

    useEffect(() => {
        setIsSave(false);
        setSaveData({});
    }, [ edit ]);


    const onChange = (name, val) => {
        const key = name.split('|');
        const nameField = key[ 0 ];
        const newData = { ...saveData };
        newData[ nameField ] = val;

        setSaveData(newData);
    };

    const save = async (obj) => {
        obj._id = data._id && data._id !==  'new'  ? data._id : '';
        const objSave = { ...item, ...obj };
        const result = await api.postData({ data: objSave, type: type }, urlApi + '/save/' + edit);
        if (result.error) {
            setError(result.error);
        }
        setEdit(null);
        setIsSave(false);
        setSaveData({});
        if (typeof updateList === 'function') {
            updateList();
        }

        // if (!obj._id && result._id) {
        //     setNewItem(result);
        // } else {
        //     console.log('Item updated', result, { ...data, ...result });
        //     setItem({ ...item, ...result });
        // }
    };

    useEffect(() => {
        setItem({ ...item, ...saveData });
    }, [ data ]);


    const validate = (name, type) => {
        const validData = { ...data, ...saveData };
        let valid = true;
        if (!validData[ name ]) {
            valid = false;
        }
        if (type !== 'date' && validData[ name ] && typeof validData[ name ] === 'object') {
            const findContent = Object.keys(validData[ name ]).find((el) => striptags(validData[ name ][ el ]).split(/\s+/)
                .join('') !== '');

            valid = !!findContent;
        }

        return valid;
    };

    useEffect(() => {
        if (isSave) {
            const newErrors = {};
            for (const name of Object.keys(fields)) {
                if (fields[ name ].require) {
                    const valid = validate(name, fields[ name ].type);
                    if (!valid) {
                        newErrors[ name ] = true;
                    }
                }
            }
            console.error(newErrors);
            if (Object.keys(newErrors).length === 0) {
                save(saveData);
                if (typeof setIsAdd === 'function') {
                    setIsAdd(false);
                }
            }
        }

        setIsSave(false);
    // eslint-disable-next-line
  }, [isSave]);

    const close = () => {
        setEdit(null);
        if (typeof setIsAdd === 'function') {
            setIsAdd(false);
        }
    };

    useEffect(() => {
        if (hidden) {
            close();
        }
    }, [ hidden ]);


    const fieldsJSX = item
        ? Object.keys(fields).map((key) => {
            let show = true;
            if (fields[ key ].roles && show) {
                show = fields[ key ].roles.includes(userData.role);
            }
            if (fields[ key ].onlyNew && !isAdd) {
                show = false;
            }

            if (!show) {
                return null;
            }
            const disabled = fields[ key ].disabled;
            if (disabled) {
                for (const disabledKey of Object.keys(disabled)) {
                    if (disabled[ disabledKey ] === true) {
                        switch (typeof data[ disabledKey ]) {
                            case 'object':
                                if (data[ disabledKey ].length > 0) {
                                    return null;
                                }
                                break;
                            default:
                                return null;
                        }
                    }
                }
            }

            return (
                <React.Fragment key = { key }>
                    { key !== 'delete' && (
                        <ItemRender
                            { ...props }
                            dataSearch = { dataSearch }
                            field = { fields[ key ] }
                            fieldKey = { key }
                            item = {{ ...item, ...saveData }}
                            setDataSearch = { setDataSearch }
                            setHidden = { setHidden }
                            setIsSave = { setIsSave }
                            setItem = { setItem }
                            onChange = { onChange }
                        />
                    )}
                </React.Fragment>
            );
        })
        : null;

    return (
        <>
            <div className = { StylesFilter.drawerContent }>
                <h2 className = { StylesFilter.h2 }>
                    { `${isAdd
                        ? `${translate[ titlesAdd[ componentName ] ] || componentName}`
                        : `${translate[ titlesEdit[ componentName ] ] || componentName}`}` }
                </h2>
                <div className = { StylesFilter.filtersList }>
                    {error && (
                        <div>
                            {error}
                        </div>
                    )}
                    {fieldsJSX}
                </div>
                <div className = { StylesFilter.drawerButtons }>
                    <div
                        className = { StylesFilter.btnOutline }
                        onClick = { () => close() }>
                        { translate.bt1 }
                    </div>
                    <div
                        className = { StylesFilter.btn }
                        onClick = { () => setIsSave(true) }>
                        { translate.bt2 }
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditItem;
