import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import StylesStat from '../../../theme/styles/stat.module.scss';
import { api } from '../../../Api';
const selectState = (state) => ({
    translate: state.swapi.translate,
});

function Charts(props) {
    const { filter } = props;
    const [ statCalls, setStatCalls ] = useState([]);
    const state = useSelector(selectState);
    const translate = state.translate;

    useEffect(() => {
        const getData = async () => {
            const data =  await api.postData({ filterData: { ...filter } }, `/getStatsProfit`);
            if (data.dataStasAll) {
                setStatCalls(data?.dataStasAll || []);
            }
        };
        getData();
        console.log(filter);
    }, [ filter ]);

    return (
        <div className = { `${StylesStat.statCalls}` }>
            { statCalls.map((item, index)=>(
                <div
                    className = { StylesStat.statCallsItem }
                    key = { index }>
                    <div className = { `${StylesStat.top}` }>
                        <div className = { `${StylesStat.name}` }>
                            { translate[ item.name ] }
                        </div>
                        <div className = { `${StylesStat.info}` }>
                            <img
                                alt = ''
                                src = { '/icons/info.svg' }
                            />
                            <div className = { `${StylesStat.tooltip}` }>
                                { translate[ item.info ] }

                            </div>
                        </div>
                    </div>
                    <div className = { `${StylesStat.data}` }>
                        <div className = { `${StylesStat.value}` }>
                            { item.value }
                        </div>
                        <div className = { `${StylesStat.dynamic} ${item.dynamic > 0 ? StylesStat.up : StylesStat.down}` }>
                            <img
                                alt = ''
                                src = { `/icons/d_${item.dynamic > 0 ? 'up' : 'down'}.svg` }
                            />
                            { `${item.dynamic > 0 ? '+' : ''}${item.dynamic}%` }
                            <span className = { `${StylesStat.lm}` }>last month</span>
                        </div>
                    </div>
                </div>
            )) }
        </div>

    );
}

export default Charts;
