import React, { useState, useEffect } from 'react';
import StylesForm from '../../../theme/styles/form.module.scss';
import Styles from './styles/select.module.scss';

export const Input = (props) => {
    const {
        valueField = '',
        nameField,
        setFieldValue,
        placeholder,
        label = '',
        labelTxt = '',
        clear,
        index = '',
        isNumber = false,
        disabled = false,
        showLength = false,
    } = props;

    const [ value, setValue ] = useState(valueField);

    useEffect(() => {
        if (clear) {
            setValue('');
        }
    }, [ clear ]);

    useEffect(() => {
        if (valueField === 'empty!') {
            setValue('');
            setFieldValue(nameField, '', index);
        } else if (valueField !== value) {
            setValue(valueField);
        }
    }, [ valueField ]);

    const onChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        setFieldValue(nameField, newValue, index);
    };

    return (
        <div className = { `${StylesForm.field} ${ Styles.wrap }` }>
            {showLength && value.length > 0 ? (
                <div>{value.length}</div>
            ) : null}
            { labelTxt && (
                <div className = { StylesForm.label }>{labelTxt}</div>
            ) }
            <input
                className = { `${Styles.input}` }
                disabled = { disabled }
                label = { label }
                name = { nameField }
                placeholder = { placeholder }
                size = 'small'
                title = { label ? label : placeholder }
                type = { isNumber ? 'number' : 'text' }
                value = { value }
                onChange = { onChange }
            />
        </div>
    );
};
