import React, { useState, useEffect } from 'react';
import { Search }  from './search';
import Styles from './styles/MultiSearch.module.scss';
import StylesForm from '../../../theme/styles/form.module.scss';

export const MultiSearch = (props) => {
    const {  valueField = [], nameField,  setFieldValue, type, placeholder, empty,
        dataSearch, removeField, labelTxt = '' } = props;
    const [ value, setValue ] = useState([]);
    const [ _placeholder, setPlaceholder ] = useState(placeholder);

    useEffect(() => {
        setValue(valueField);
        const value = valueField[ 0 ]?.label || empty;
        setPlaceholder(`${placeholder} ${value ? value : ''}`);
    }, [ valueField, valueField.length ]);

    const handleChange = (name, val) => {
        const selectedItem = valueField ? valueField.find((item) => item.value === val) : null;
        if (selectedItem) {
            const newValue = [ ...value ];
            newValue.map((el)=> {
                el.hover =  el.value === selectedItem.value;

                return el;
            });
            setValue(newValue);
            setFieldValue(nameField, newValue);
        } else {
            const newValue = [ ...value, { label: name, value: val }];
            setValue(newValue);
            setFieldValue(nameField, newValue);
        }
    };


    return  (
        <div className = { Styles.wrap }>
            { labelTxt && (
                <div className = { StylesForm.label }>{labelTxt}</div>
            ) }
            <Search
                clear
                multi
                dataSearch = { dataSearch }
                nameField = { nameField }
                placeholder = { _placeholder }
                removeField = { removeField }
                selectedList  = { valueField }
                setFieldValue  = { handleChange }
                type = { type }
            />
        </div>
    );
};
