import React, { useState } from 'react';
import StylesForm from '../../../theme/styles/form.module.scss';
import StylesFilter from '../../../theme/styles/filter.module.scss';
import { useSelector } from 'react-redux';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

export const FilterSearch = (props) => {
    const { fields, setFilter, filter, setFilterData } = props;
    const keysWithSearchTrue = Object.keys(fields).find((key) => fields[ key ].isSearch === true);
    const [ value, setValue ] = useState('');
    const state = useSelector(selectState);
    const translate = state.translate;

    const onChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        setFilter({ ...filter, [ keysWithSearchTrue ]: newValue });
    };

    return keysWithSearchTrue ? (
        <div className = { `${StylesForm.search}` }>
            <svg
                fill = 'none'
                height = '15'
                viewBox = '0 0 14 15'
                width = '14'
                xmlns = 'http://www.w3.org/2000/svg'>
                <path
                    clipRule = 'evenodd'
                    d = 'M13.6667 5.93408C13.6667 8.69551 11.4281 10.9341 8.66671 10.9341C7.51228 10.9341 6.44924 10.5428 5.60292 9.88574C5.57104 9.95318 5.52722 10.0164 5.47145 10.0722L1.47145 14.0722C1.2111 14.3325 0.788986 14.3325 0.528636 14.0722C0.268287 13.8118 0.268287 13.3897 0.528636 13.1293L4.52864 9.12934C4.58441 9.07357 4.64761 9.02974 4.71505 8.99787C4.05794 8.15155 3.66671 7.08851 3.66671 5.93408C3.66671 3.17266 5.90528 0.934082 8.66671 0.934082C11.4281 0.934082 13.6667 3.17266 13.6667 5.93408ZM12.3334 5.93408C12.3334 3.90904 10.6918 2.26742 8.66671 2.26742C6.64166 2.26742 5.00004 3.90904 5.00004 5.93408C5.00004 7.95913 6.64166 9.60075 8.66671 9.60075C10.6918 9.60075 12.3334 7.95913 12.3334 5.93408Z'
                    fill = '#898989'
                    fillRule = 'evenodd'
                />
            </svg>
            <input
                placeholder = { 'Search' }
                type = { 'text' }
                value = { value }
                onChange = { onChange }
            />
            <div
                className = { StylesFilter.btn }
                onClick = { () => setFilterData() }>
                { translate.bt3 }
            </div>
        </div>
    ) : null;
};
